import { React, Suspense, lazy } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {
  ProtectedRoute,
  UnProtectedRoute,
} from "./Components/checkAuthentication";

import ForgotPassword from "./pages/authentication/forgotPassword";
import Login from "./pages/authentication/login";
import ResetPassword from "./pages/authentication/ResetPassword";
import Logout from "./pages/authentication/Logout";
import DotsLoader from "./Components/DotsLoader";
import PageNotFound from "./pages/PageNotFound";
import { getTheme } from "./getTheme";
import { IsAuthorized } from "./Common/utilsFunctions/IsAuthorized";
import EMRConnectivityComponent from "./pages/Settings/SideTabs/EMRConnectivityComponent";
import ClearingHouseConnectivityComponent from "./pages/Settings/SideTabs/ClearingHouseConnectivityComponent";
import OtherSettingsComponent from "./pages/Settings/SideTabs/OtherSettingsComponent";
import ConfigurationComponent from "./pages/Settings/SideTabs/ConfigurationComponent";
import PrimaryDetailsComponent from "./pages/Settings/SideTabs/PrimaryDetailsComponent";
import IntegrationCredentials from "./pages/Settings/SideTabs/IntegrationCredentials";
import Authorizations from "./pages/Settings/SideTabs/Authorizations";
import UserActivity from "./pages/Settings/SideTabs/UserActivity";
import RedirectURL from "./pages/RedirectPage";

const Authorization = lazy(() => import("./pages/authorization2"));
const BillingDashboard = lazy(() => import("./pages/Billing/BillingDashBoard"));
const EDIBillingDashboard = lazy(() =>
  import("./pages/EDIBilling/BillingDashBoard")
);
const BillingSecondScreen = lazy(() =>
  import("./pages/Billing/BillingSecondScreen")
);
const EDIBillingSecondScreen = lazy(() =>
  import("./pages/EDIBilling/BillingSecondScreen")
);
const BillingThirdScreen = lazy(() =>
  import("./pages/Billing/BillingThirdScreen")
);
const EDIBillingThirdScreen = lazy(() =>
  import("./pages/EDIBilling/BillingThirdScreen")
);
const BlankHipps2 = lazy(() => import("./pages/BlankHipps2"));
const Census = lazy(() => import("./pages/Census"));

const Dashboard = lazy(() => import("./pages/Dashboard"));

const Users = lazy(() => import("./pages/Dashboard/Users"));
const DischargePlanning = lazy(() => import("./pages/DischargePlanning"));
const EpisodevisitAuthSummary = lazy(() =>
  import("./pages/EpisodeVisitAuthSummary")
);
const IncorrectMRNs = lazy(() => import("./pages/IncorrectMRNs"));
const InTake = lazy(() => import("./pages/InTake"));
const AdHOC = lazy(() => import("./pages/AdHOC"));
const SyncHistory = lazy(() => import("./pages/SyncHistory"));
const AuthorizationSummary = lazy(() => import("./pages/AuthorizationSummary"));
const LupaRisk = lazy(() => import("./pages/LupaRisk"));
const PatientEligibility = lazy(() => import("./pages/PatientEligibility"));

const PatientResponsibilityExperimental = lazy(() =>
  import("./pages/PatientResponsibilityExperimental")
);

const DeniedCliams = lazy(() => import("./pages/DeniedClaims"));
const DeniedClaimsForExperimental = lazy(() =>
  import("./pages/DeniedClaimsForExperimental")
);
const EDIDeniedCliams = lazy(() => import("./pages/EDIDeniedClaims"));

const PendingInvoices = lazy(() => import("./pages/PendingInvoices"));
const AutoPosting = lazy(() => import("./pages/AutoPosting"));
const PivotTable = lazy(() => import("./pages/PivotTable"));
const PCCCensus = lazy(() => import("./pages/PCCCencus"));
const PDGMmanagement = lazy(() => import("./pages/PDGM-Management"));
const InhouseProcessing = lazy(() =>
  import("./pages/PendingOrders/InhouseProcessing")
);
const PendingOrders = lazy(() => import("./pages/PendingOrders/PendingOrders"));
const PendingSignatures = lazy(() =>
  import("./pages/PendingOrders/PendingSignatures")
);
const Tobesent = lazy(() => import("./pages/PendingOrders/Tobesent"));
const PendingTasks1 = lazy(() => import("./pages/PendingTasks1"));
const PerVisitManagement = lazy(() => import("./pages/PerVisitManagement"));
const Productivity = lazy(() => import("./pages/Productivity2"));
const Referrals = lazy(() => import("./pages/Referrals"));
const ReferralPhysicianLoad = lazy(() => import("./pages/Referrals/Orders"));
const ReviewChoiceDemostration = lazy(() =>
  import("./pages/ReviewChoiceDemostration")
);
const ScheduleDeviation = lazy(() => import("./pages/ScheduleDeviation"));
const Settings = lazy(() => import("./pages/Settings"));
const AgedAR = lazy(() => import("./pages/AgedAR"));
const EDIAgedAR = lazy(() => import("./pages/EDIAgedAR"));
const BillingBatchReport = lazy(() => import("./pages/BillingBatchReport"));
const RemittancesInusrance = lazy(() => import("./pages/RemittancesInsurance"));
const BillingReport = lazy(() => import("./pages/BillingReport"));
const ToBeBilledReports = lazy(() => import("./pages/ToBeBilledReports"));
const AthenaHealth = lazy(() => import("./pages/athenaHealth"));
const DataPlayground = lazy(() => import("./pages/DataPlayground"));
const ReleaseHistory = lazy(() => import("./pages/Settings/ReleaseHistory"));
const NewRelease = lazy(() =>
  import("./pages/Settings/ReleaseHistory/NewRelease")
);
const UpdateRelease = lazy(() =>
  import("./pages/Settings/ReleaseHistory/UpdateRelease")
);
const ViewTreeStructure = lazy(() => import("./pages/ViewTreeStructure"));

const Help = lazy(() => import("./pages/Help"));
const EditTrainingManual = lazy(() =>
  import("./pages/Help/EditTrainingManual")
);
const ManageStatus = lazy(() =>
  import("./pages/Settings/PDTables/ManageTables/MangeStatus")
);
const ManageRates = lazy(() =>
  import("./pages/Settings/PDTables/ManageTables/ManageRates")
);
const ProviderProfitability = lazy(() =>
  import("./pages/ProviderProfitability")
)


let selectedTheme = getTheme("theme1");
let stopRoutes = window.location.href.includes("hnts");

function App() {
  return (
    <div className="App themeStyles" style={selectedTheme}>
      <Suspense fallback={<DotsLoader />}>
        <Router>
          <Routes>
            <Route
              exact
              path="/athena-health"
              element={
                <UnProtectedRoute>
                  <AthenaHealth />{" "}
                </UnProtectedRoute>
              }
            />
            <Route
              exact
              path="/redirect-url"
              element={
                <RedirectURL />
              }
            />
            <Route
              exact
              path="/login"
              element={
                <UnProtectedRoute>
                  <Login />
                </UnProtectedRoute>
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                <UnProtectedRoute>
                  <ForgotPassword />
                </UnProtectedRoute>
              }
            />
            <Route
              exact
              path="/accounts/password-reset-confirm/:uuid/:token"
              element={
                <UnProtectedRoute>
                  <ResetPassword />
                </UnProtectedRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="billing-dashboard"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <BillingDashboard />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="edi-billing-dashboard"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <EDIBillingDashboard />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="billing-dashboard/insurance-listing"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <BillingSecondScreen />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="edi-billing-dashboard/insurance-listing"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <EDIBillingSecondScreen />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing-dashboard/patient-claims"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <BillingThirdScreen />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="edi-billing-dashboard/patient-claims"
              element={
                <>
                  {IsAuthorized("BillingScreen", "page_level") ? (
                    <ProtectedRoute>
                      <EDIBillingThirdScreen />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="schedule-deviation"
              element={
                <>
                  {IsAuthorized("ScheduleDeviation", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <ScheduleDeviation />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing/aged-ar"
              element={
                <>
                  {IsAuthorized("AgedAr", "page_level") ? (
                    <ProtectedRoute>
                      <AgedAR />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="experimental/aged-ar"
              element={
                <>
                  {IsAuthorized("AgedAr", "page_level") ? (
                    <ProtectedRoute>
                      <EDIAgedAR />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing/billing-and-remittances/billing-batch-report"
              element={
                <>
                  {IsAuthorized("BillingBatchReport", "page_level") ? (
                    <ProtectedRoute>
                      <BillingBatchReport />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing/billing-and-remittances/remittances-Insurance"
              element={
                <>
                  {IsAuthorized("BillingRemittances", "page_level") ? (
                    <ProtectedRoute>
                      <RemittancesInusrance />{" "}
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing/billing-and-remittances"
              element={
                <>
                  {IsAuthorized("BillingBatchvsRemittance", "page_level") ? (
                    <ProtectedRoute>
                      <BillingReport />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="billing/to-be-billed"
              element={
                <>
                  {IsAuthorized("ToBeBilled", "page_level") ? (
                    <ProtectedRoute>
                      <ToBeBilledReports />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="incorrect-mrns"
              element={
                <>
                  {IsAuthorized("IncorrectMRNs", "page_level") ? (
                    <ProtectedRoute>
                      <IncorrectMRNs />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="lupa-risk"
              element={
                <>
                  {IsAuthorized("LupaRisk", "page_level") ? (
                    <ProtectedRoute>
                      <LupaRisk />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="blank-hipps"
              element={
                <>
                  {IsAuthorized("BlankHipps", "page_level") ? (
                    <ProtectedRoute>
                      <BlankHipps2 />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="episode-visit-auth-summary"
              element={
                <>
                  {IsAuthorized("EpisodeVisitAuthSummary", "page_level") ? (
                    <ProtectedRoute>
                      <EpisodevisitAuthSummary />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="discharge-planning"
              element={
                <>
                  {IsAuthorized("DischargePlanning", "page_level") ? (
                    <ProtectedRoute>
                      <DischargePlanning />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="productivity"
              element={
                <>
                  {IsAuthorized("Productivity", "page_level") ? (
                    <ProtectedRoute>
                      <Productivity />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="past-due-visits"
              element={
                <>
                  {IsAuthorized("PastDueVisits", "page_level") ? (
                    <ProtectedRoute>
                      <PendingTasks1 />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="census"
              element={
                <>
                  {IsAuthorized("Census", "page_level") ? (
                    <ProtectedRoute>
                      <Census />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="orders"
              element={
                <>
                  {IsAuthorized("Orders", "page_level") ? (
                    <ProtectedRoute>
                      <PendingOrders />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="orders/inhouse-processing"
              element={
                <>
                  {IsAuthorized("Orders", "page_level") ? (
                    <ProtectedRoute>
                      <InhouseProcessing />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="orders/to-be-sent"
              element={
                <>
                  {IsAuthorized("Orders", "page_level") ? (
                    <ProtectedRoute>
                      <Tobesent />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="orders/pending-signatures"
              element={
                <>
                  {IsAuthorized("Orders", "page_level") ? (
                    <ProtectedRoute>
                      <PendingSignatures />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="patient-eligibility"
              element={
                <>
                  {IsAuthorized("PatientEligibility", "page_level") ? (
                    <ProtectedRoute>
                      <PatientEligibility />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="patient-responsibility"
              element={
                <>
                  {IsAuthorized("PatientResponsibility", "page_level") ? (
                    <ProtectedRoute>
                      <PatientResponsibilityExperimental />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="pending-invoices"
              element={
                <ProtectedRoute>
                  <PendingInvoices />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="denied-claims"
              element={
                <>
                  {IsAuthorized("DenialClaims", "page_level") ? (
                    <ProtectedRoute>
                      <DeniedCliams />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="work-in-progress/denied-claims"
              element={
                <>
                  {IsAuthorized("DenialClaims", "page_level") ? (
                    <ProtectedRoute>
                      <DeniedClaimsForExperimental />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="edi-denied-claims"
              element={
                <>
                  {IsAuthorized("DenialClaims", "page_level") ? (
                    <ProtectedRoute>
                      <EDIDeniedCliams />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            {!stopRoutes ? (
              <Route
                exact
                path="autoposting"
                element={
                  <>
                    {IsAuthorized("AutoPosting", "page_level") ? (
                      <ProtectedRoute>
                        <AutoPosting />
                      </ProtectedRoute>
                    ) : (
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <Navigate
                          to="/"
                          replace={true}
                          state={{ isUnAuthorized: false }}
                        />
                      </Suspense>
                    )}
                  </>
                }
              />
            ) : (
              ""
            )}


            <Route
              exact
              path="pivot-table"
              element={
                <ProtectedRoute>
                  <PivotTable />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="pcc-dashboard"
              element={
                <>
                  {IsAuthorized("PCCDashboard", "page_level") ? (
                    <ProtectedRoute>
                      <PCCCensus />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="authorization"
              element={
                <>
                  {IsAuthorized("MissingAuthorizations", "page_level") ? (
                    <ProtectedRoute>
                      <Authorization />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="review-choice-demonstration"
              element={
                <>
                  {IsAuthorized("ReviewChoiceDemonstration", "page_level") ? (
                    <ProtectedRoute>
                      <ReviewChoiceDemostration />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="pdgm-management"
              element={
                <>
                  {IsAuthorized("PDGMManagement", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <PDGMmanagement />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="users"
              element={
                <>
                  {IsAuthorized("Users", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <Users />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />

            <Route
              exact
              path="settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/emr-connectivity"
              element={
                <ProtectedRoute>
                  <EMRConnectivityComponent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/clear-house-connectivity"
              element={
                <ProtectedRoute>
                  <ClearingHouseConnectivityComponent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/other-settings"
              element={
                <ProtectedRoute>
                  <OtherSettingsComponent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/integration-credentials"
              element={
                <ProtectedRoute>
                  <IntegrationCredentials />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/authorizations"
              element={
                <ProtectedRoute>
                  <Authorizations />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/configurations"
              element={
                <ProtectedRoute>
                  <ConfigurationComponent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/primary-details"
              element={
                <ProtectedRoute>
                  <PrimaryDetailsComponent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/user-activity"
              element={
                <ProtectedRoute>
                  <UserActivity />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="settings/primary-details/staff-details/manage-status"
              element=
              {
                <>
                  {IsAuthorized("Settings", "staff_details") ? (
                    <ProtectedRoute>
                      <ProtectedRoute>
                        <ManageStatus />
                      </ProtectedRoute>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="settings/primary-details/staff-details/manage-rates"
              element=
              {
                <>
                  {IsAuthorized("Settings", "staff_details") ? (
                    <ProtectedRoute>
                      <ProtectedRoute>
                        <ManageRates />
                      </ProtectedRoute>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="settings/data-playground"
              element={
                <>
                  {IsAuthorized("DataPlayGround", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <DataPlayground />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="/release-history"
              element={
                <>
                  {IsAuthorized("ReleaseHistory", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <ReleaseHistory />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="/release-history/add"
              element={
                <>
                  {IsAuthorized("ReleaseHistory", "add_new_release_history") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <NewRelease />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="/release-history/update"
              element={
                <>
                  {IsAuthorized("ReleaseHistory", "update_release_history") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <UpdateRelease />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            {!stopRoutes ? (
              <Route
                exact
                path="view-tree-structure"
                element={
                  <>
                    {IsAuthorized("ViewTreeStructure", "page_level") ? (
                      <ProtectedRoute>
                        <Suspense fallback={<DotsLoader height="vh-100" />}>
                          <ViewTreeStructure />
                        </Suspense>
                      </ProtectedRoute>
                    ) : (
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <Navigate
                          to="/"
                          replace={true}
                          state={{ isUnAuthorized: false }}
                        />
                      </Suspense>
                    )}
                  </>
                }
              />
            ) : (
              ""
            )}
            <Route
              exact
              path="referrals"
              element={
                <>
                  {IsAuthorized("Referrals", "page_level") ? (
                    <ProtectedRoute>
                      <Referrals />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="referrals-physician-load"
              element={
                <>
                  {IsAuthorized("Referrals", "page_level") ? (
                    <ProtectedRoute>
                      <ReferralPhysicianLoad />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="per-visit-management"
              element={
                <>
                  {IsAuthorized("PerVisitManagement", "page_level") ? (
                    <ProtectedRoute>
                      <PerVisitManagement />
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="intake"
              element={
                <ProtectedRoute>
                  <InTake />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="ad-hoc"
              element={
                <ProtectedRoute>
                  <AdHOC />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="sync-history"
              element={
                <>
                  {IsAuthorized("SyncHistory", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <SyncHistory />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="authorization-summary"
              element={
                <ProtectedRoute>
                  <AuthorizationSummary />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/help"
              element={
                <>
                  {IsAuthorized("Help", "page_level") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <Help />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="/help/update"
              // path="edi-insurance-listing/:id"
              element={
                <>
                  {IsAuthorized("Help", "update_help") ? (
                    <ProtectedRoute>
                      <Suspense fallback={<DotsLoader height="vh-100" />}>
                        <EditTrainingManual />
                      </Suspense>
                    </ProtectedRoute>
                  ) : (
                    <Suspense fallback={<DotsLoader height="vh-100" />}>
                      <Navigate
                        to="/"
                        replace={true}
                        state={{ isUnAuthorized: false }}
                      />
                    </Suspense>
                  )}
                </>
              }
            />
            <Route
              exact
              path="*"
              element={
                <ProtectedRoute>
                  <PageNotFound />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/provider_profitability"
              element={
                <ProtectedRoute>
                  <ProviderProfitability />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
