import React, { Suspense, lazy, useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

import { tenantIdUrl, tenantSettingsUrl } from "../../../Utils/Urls";
import DotsLoader from "../../../Components/DotsLoader";
import { validateForm } from "../../../Common/FormTemplates/callToActions";
import LoaderComponent from "../../../Common/LoaderComponent";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";
import { sendLogToCloudWatch } from "../../../Common/utilsFunctions/sendLogToCloudWatch";
import { AxiosInstance } from "../../../Components/Util_Functions/AxiosInstance";

const TextFiledComponent = lazy(() =>
  import("../../../Common/FormTemplates/TextFiledComponent")
);
const SelectDropDown = lazy(() => import("../../../Common/SelectDropDown"));
const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const ToasterComponet = lazy(() => import("../../../Common/ToasterComponet"));

const OtherSettingsComponent = () => {
  const navigate = useNavigate();

  //Toaster States
  const [showToaster, setShowToaster] = useState(false);
  const [toastColor, setToastColor] = useState("success");
  const [toasterMessage, setToasterMessage] = useState("");

  /*BreadCrumb States */
  const breadcrumb = [
    { name: "Home", link: "/", isLink: true },
    { name: "Settings", link: "/", isLink: false },
  ];

  const [tenantId, settenantId] = useState("");
  //smtp settings states
  const [SMTPSpinner, setSMTPSpinner] = useState(false);
  const [smtpSettings, setSmtpSettings] = useState({
    host: "",
    user: "",
    password: "",
  });
  const [smtpSettingsErrors, setSmtpSettingsErrors] = useState({
    host: false,
    user: false,
    password: false,
  });

  //Other settings states
  const [timeZoneSpinner, settimeZoneSpinner] = useState(false);
  const timeZoneOptions = [
    { label: "None", value: "None" },
    { label: "US-Eastern", value: "US-Eastern" },
    { label: "US-Central", value: "US-Central" },
    { label: "US-Mountain", value: "US-Mountain" },
    { label: "US-Pacific", value: "US-Pacific" },
  ];
  const [defaultTimeZone, setdefaultTimeZone] = useState({
    label: "None",
    value: "None",
  });
  const [defaultDateFormat, setdefaultDateFormat] = useState({
    label: "None",
    value: "None",
  });
  const dateFormatOptions = [
    { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
    { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
    { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
    { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
  ];

  //Handling configurations Actions
  const [defaultweek, setdefaultweek] = useState("");
  const [threshold, setthreshold] = useState("");

  /*Update SMTP triggering function */
  const handleotherSettingsSubmit = () => {
    let errors = validateForm(smtpSettings);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });
    setSmtpSettingsErrors(errors);
    if (!isEmptyFormData) {
      setSMTPSpinner(true);
      let { host, user, password } = smtpSettings;
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: "None",
        emr_sftp: "None",
        clearing_house_sftp: "None",
        clearning_house_hmac: "None",
        clearning_house_non_hmac: "None",
        other_settings: "None",
        other_settings_smtp: {
          host: host,
          user: user,
          password: password,
        },
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  /*Update time and date format triggering function */
  const handleTimeandDateFormats = () => {
    let dataTobeSend1 = {
      id: tenantId,
      emr_settings: "None",
      emr_sftp: "None",
      clearing_house_sftp: "None",
      clearning_house_hmac: "None",
      clearning_house_non_hmac: "None",
      other_settings: {
        productivity: {
          default_week: defaultweek,
          threshold: threshold,
        },
        time_zone: defaultTimeZone.value,
        date_format: defaultDateFormat.value,
      },
      other_settings_smtp: "None",
    };
    settimeZoneSpinner(true);
    tenantSettingsUtilFunction(dataTobeSend1);
  };

  /*Rest call function for updating tenant details */
  const tenantSettingsUtilFunction = (dataTobeSend) => {
    AxiosInstance.put(tenantSettingsUrl, dataTobeSend, {
      customHeaders: {
        'Screen-Name': 'Settings',
        "Screen-Section": "Other Settings",
        "Screen-Component": "Update Tenant Settings",
      }
    })
      .then((response) => {
        if (dataTobeSend.other_settings !== "None") {
          let previousLocallyStored_hnts_user = JSON.parse(
            localStorage.getItem("hnts_user")
          );
          previousLocallyStored_hnts_user.settings =
            dataTobeSend.other_settings;
          localStorage.setItem(
            "hnts_user",
            JSON.stringify(previousLocallyStored_hnts_user)
          );
        }
        sendLogToCloudWatch(response);
        setToasterMessage(response.data.msg);
        setShowToaster(true);
        setToastColor("success");
        window.scroll(0, 0);
      })
      .catch(async (error) => {
        sendLogToCloudWatch(error?.response);
        setToasterMessage(
          error?.data?.msg ? error.data.msg : "Updating tenant details Failed"
        );
        setShowToaster(true);
        setToastColor("danger");
        window.scroll(0, 0);
      })
      .finally(() => {
        setSMTPSpinner(false);
        settimeZoneSpinner(false);
      });
  };

  /*In this side effect we'll call get tenant details API */
  useEffect(() => {
    sendLogToCloudWatch("settings-other-settings rendered successfully");
    AxiosInstance.get(tenantIdUrl, {
      customHeaders: {
        'Screen-Name': 'Settings',
        "Screen-Section": "Other Settings",
        "Screen-Component": "Get Tenant Settings",
      }
    })
      .then((response) => {
        var required_other_settings_smtp =
          response.data.Response[0].other_settings_smtp;
        var required_other_settings = response.data.Response[0].other_settings;
        settenantId(response.data.Response[0].id);

        //Setting default values for Other Settings
        if (required_other_settings_smtp?.length !== 0) {
          let { host, user, password } = required_other_settings_smtp;
          setSmtpSettings({
            host: host,
            user: user,
            password: password,
          });
        }

        if (required_other_settings && required_other_settings.length !== 0) {
          setdefaultweek(
            required_other_settings.productivity &&
            required_other_settings.productivity.default_week
          );
          setthreshold(
            required_other_settings.productivity &&
            required_other_settings.productivity.threshold
          );
          setdefaultTimeZone({
            label: required_other_settings.time_zone,
            value: required_other_settings.time_zone,
          });
          setdefaultDateFormat({
            label: required_other_settings.date_format,
            value: required_other_settings.date_format,
          });
        }
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        sendLogToCloudWatch(error?.response);
      });
  }, []);

  return (
    <div className="container-body mb-0 ">
      <div className="mx-3">
        <Suspense>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>
      </div>

      {showToaster ? (
        <section className="fixed-top mt-5">
          <Suspense>
            <ToasterComponet
              setShowToaster={setShowToaster}
              color={toastColor}
              toasterMessage={toasterMessage}
            />
          </Suspense>
        </section>
      ) : null}

      <div>
        <Container fluid className="tab-container side-tabs settings">
          <Row xs={12}>
            {/* Left Tab */}
            <Col
              className="tabs-section1 leftTab"
              xs={2}
            >
              <div className="d-grid gap-2 px-2">
                {IsAuthorized("Settings", "emr_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/emr-connectivity");
                    }}
                  >
                    EMR Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/clear-house-connectivity");
                    }}
                  >
                    Clearing House Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "other_settings") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="button-default"
                  >
                    Other Settings
                  </Button>
                )}
                {IsAuthorized("Settings", "credentials") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/integration-credentials");
                    }}
                  >
                    Integration Credentials
                  </Button>
                )}
                {IsAuthorized("Settings", "authorizations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/authorizations");
                    }}
                  >
                    Authorizations
                  </Button>
                )}
                {IsAuthorized("Settings", "configurations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/configurations");
                    }}
                  >
                    Configurations
                  </Button>
                )}

                {localStorage.getItem("primaryDetailsSelectedTab") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/primary-details");
                    }}
                  >
                    Primary details
                  </Button>
                )}

                {IsAuthorized("Settings", "user_activity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/user-activity");
                    }}
                  >
                    User Activity
                  </Button>
                )}
                {IsAuthorized("DataPlayGround", "page_level") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/data-playground");
                    }}
                  >
                    Data Playground
                  </Button>
                )}
              </div>
            </Col>
            <Col className="tabs-section1 rightTab account-admin" xs={10}>
              <div className="ps-3 pe-5 me-3 mb-4">
                <div className="section sftp-settings">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">SMTP Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Host"}
                          placeHolder={"enter host"}
                          name="host"
                          value={smtpSettings.host}
                          stateValue={smtpSettings}
                          setStateValue={setSmtpSettings}
                          validation={smtpSettingsErrors.host}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"User"}
                          placeHolder={"enter user"}
                          name="user"
                          value={smtpSettings.user}
                          stateValue={smtpSettings}
                          setStateValue={setSmtpSettings}
                          validation={smtpSettingsErrors.user}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Password"}
                          placeHolder={"enter password"}
                          name="password"
                          value={smtpSettings.password}
                          stateValue={smtpSettings}
                          setStateValue={setSmtpSettings}
                          validation={smtpSettingsErrors.password}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  <Button
                    className="emr-button px-3 py-2"
                    onClick={handleotherSettingsSubmit}
                  >
                    Update SMTP Settings &nbsp;&nbsp;&nbsp;
                    {SMTPSpinner && <LoaderComponent />}
                  </Button>
                </div>
              </div>
              <div className="ps-3 pe-5 me-3 mb-4">
                <div className="section sftp-settings">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">Other Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <SelectDropDown
                          label={"Default Timezone"}
                          dropDownData={timeZoneOptions}
                          defaultVaue={defaultTimeZone}
                          setDropDownDataState={setdefaultTimeZone}
                          menuOptionsPosition="absolute"
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <SelectDropDown
                          label={"Default Date Format"}
                          dropDownData={dateFormatOptions}
                          defaultVaue={defaultDateFormat}
                          setDropDownDataState={setdefaultDateFormat}
                          menuOptionsPosition="absolute"
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  <Button
                    className="emr-button px-3 py-2"
                    onClick={handleTimeandDateFormats}
                  >
                    Update Other Settings &nbsp;&nbsp;&nbsp;
                    {timeZoneSpinner && <LoaderComponent />}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OtherSettingsComponent;
