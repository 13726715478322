import React from "react";
import "./dots.css";

const DotsLoader = ({ height = "form-field-height", background = "" }) => {
  return (
    <div className={`${height} ${background === "secondary" ? "bg-secondary" : "bg-white"} loading-dots`}>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
      <div className="loading-dots--dot"></div>
    </div>
  );
};

export default DotsLoader;
