// import React, { Profiler } from "react";
import { useLocation, Navigate } from "react-router-dom";

import Footer from "./Footer";
import Layout from "./Layout";
// import { logUIPageRenderPerformance } from "../Utils/Performance";

export function ProtectedRoute({ children }) {
    const location = useLocation();
    if (localStorage.getItem("hnts_user")) {
        // return <Profiler id={location.pathname} onRender={logUIPageRenderPerformance}>
        //     <Layout>{children}<Footer /></Layout>
        // </Profiler>;
        return <Layout>{children}<Footer /></Layout>
    } else {
        return <Navigate to="/login" replace state={{ path: location.pathname }} />;
    }
}

export function UnProtectedRoute({ children }) {
    const location = useLocation();
    if (localStorage.getItem("hnts_user")) {
        return <Navigate to="/" replace state={{ path: location.pathname }} />;
    } else {
        // return <Profiler id={location.pathname} onRender={logUIPageRenderPerformance}>
        //     {children}
        // </Profiler>;
        return children;
    }
}
