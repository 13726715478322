import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";

import "./index.css";

const SelectDropDown = ({
  label, //Name of the dropdown
  labelTransparency = true, //label name transparency when field id disabled
  dropDownData, //dropdown option
  defaultVaue, //default selected value
  setDropDownDataState, //updating the state of dropdown
  isReadOnly, //is it only readable
  isMandatory, //is it mandatory
  validation, // to check the validation
  //The below three states we'll use for form validation not evry time we'll use this
  name, //to update form state through this dropdown name
  formData, //form data("we'll use this for form validation")
  setFormData, //to update form data("we'll use this for form validation")
  menuPlacement = "auto",
  menuOptionsPosition = "fixed",
  noMarginBottom = false,
  isClearable = false
}) => {


  /*****This we are using to close the menu automaticaaly when user scrolls out of the menu ****/
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setMenuIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside, true);
    };
  }, []);
  /*****This we are using to close the menu automaticaaly when user scrolls out of the menu ****/

  return (
    <div ref={selectRef} className={`select-drop-down ${noMarginBottom ? "" : "mb-3"}`}>
      {label ? (
        <Form.Label
          className={`fs-12 mb-2 ${labelTransparency !== undefined && labelTransparency
              ? ""
              : "opacity-50"
            }`}
        >
          {label}
          {isMandatory ? <span className="text-danger">&nbsp;*</span> : ""}
        </Form.Label>
      ) : (
        ""
      )}
      <Select
        isDisabled={isReadOnly !== undefined && isReadOnly ? true : false}
        value={defaultVaue}
        onChange={(e) => {
          setDropDownDataState({
            value: e?.value ? e.value : "",
            label: e?.label ? e.label : "select",
          });
          formData ? (
            setFormData({ ...formData, [name]: e?.value ? e.value : "" })
          ) : (
            <></>
          );
        }}
        options={dropDownData}
        classNamePrefix="react-select"
        className={
          validation && defaultVaue.value.length === 0
            ? "invalid-input p-0"
            : "p-0"
        }
        isClearable={isClearable}
        menuPlacement={menuPlacement}
        menuPosition={menuOptionsPosition}
        // menuShouldBlockScroll={true}
        menuShouldScrollIntoView={true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
      {isMandatory && (
        <Form.Text
          bsPrefix="app-form-text form-text"
          className={
            validation && defaultVaue.value.length === 0 ? "text-danger" : ""
          }
        >
          {validation && defaultVaue.value.length === 0
            ? "This field is mandatory"
            : ""}
        </Form.Text>
      )}
    </div>
  );
};

export default React.memo(SelectDropDown);
