import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { useNavigate } from "react-router-dom";
import LoginForm from "./loginForm.js";
import "bootstrap/dist/css/bootstrap.min.css";
import frame from "../../../assets/images/frame.png";
import healthcare from "../../../assets/images/healthcare.png";
import background from "../../../assets/images/background.svg";
import vigeoDash from "../../../assets/images/VigeoDashLogo.svg";
import "../../../assets/css/Authentication.css";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Login() {
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");
  const [toastColor, settoastColor] = useState("light");

  const location = useLocation();
  var sessionlogout = sessionStorage.getItem("sessionlogout");

  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        console.log(response);
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();

    //Default Positions for productivity
    if (sessionlogout === "autologout") {
      setShowToast(true);
      settoastColor("danger");
      setToastMsg("Your session has expired. Please login again");
    }
    setTimeout(() => {
      sessionStorage.clear();
      // window.history.replaceState({}, document.title)
    }, 3000);
  }, []);
  let navigate = useNavigate();
  return (
    <div className="fill-height">
      <Container fluid className="login-section">
        {showToast ? (
          <Row className="toast-row mt-5">
            <Col>
              <div className="toast-conatiner">
                <Toast
                  onClose={() => setShowToast(false)}
                  show={showToast}
                  className={toastType}
                  bg={toastColor}
                  delay={5000}
                  autohide
                >
                  <Toast.Body className="text-center">{toastMsg}</Toast.Body>
                </Toast>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <div className="header-section">
          <div className="logo-section">
            {/* <LazyImage
              placeholder={`${vigeoDash}?blur=10`}
              uri={vigeoDash}
              render={(src, style) => (
                <img src={src} className="site-logo" alt="logo" />
              )}
            /> */}
            <img src={vigeoDash} className="site-logo" alt="logo" />
          </div>
        </div>

        <div className="content input-height">
          <div className="top-container">
            <Row>
              <Col md="12">
                <div className="frame-section">
                  <div className="frame-header">
                    Homecare Clinical Dashboard
                  </div>
                  <div className="frame-subline">
                    Track and update all at one place
                  </div>
                  <div className="body-container">
                    <Row>
                      <Col md="6" className="d-none d-lg-block">
                        <div className="frame-img">
                          {/* <LazyImage
                            placeholder={`${frame}?blur=10`}
                            uri={frame}
                            render={(src, style) => (
                              <img src={src} className="site-logo" alt="logo" />
                            )}
                          /> */}
                          <img src={frame} alt="Frame" />
                        </div>
                      </Col>
                      <Col md="6">
                        <LoginForm navigate={navigate} ipAddress={ipAddress} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <div className="powered-by">
                <div className="powered-by-text">Powered by</div>
                <div className="powered-by-img">
                  {/* <LazyImage
                    placeholder={`${healthcare}?blur=10`}
                    uri={healthcare}
                    render={(src, style) => (
                      <img src={src} className="site-logo" alt="logo" />
                    )}
                  /> */}
                  <img src={healthcare} alt="Frame" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="background-img">
          {/* <LazyImage
            placeholder={`${background}?blur=10`}
            uri={background}
            render={(src, style) => (
              <img src={src} className="site-logo" alt="logo" />
            )}
          /> */}
          <img
            src={background}
            alt=""
            style={{ width: "158.79px", height: "689.83px" }}
          />
        </div>
      </Container>
    </div>
  );
}

export default Login;
