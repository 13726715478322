const AllTimeFormats = (date) => {
  let value = ""
  const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));
  let timeZone = hnts_user.settings.time_zone
  // ✅ convert date to CST (Central Standard Time)
  if (timeZone === "US-Central" || timeZone === "None" || timeZone === undefined) {
    value = date.toLocaleString("en-US", { timeZone: "CST", dateStyle: 'short', timeStyle: 'short' }).split(",");
  }
  if (timeZone === "US-Eastern") {
    value = date.toLocaleString("en-US", { timeZone: "America/New_York", dateStyle: 'short', timeStyle: 'short' }).split(",");
  }
  if (timeZone === "US-Mountain") {
    value = date.toLocaleString("en-US", { timeZone: "America/Chihuahua", dateStyle: 'short', timeStyle: 'short' }).split(",");
  }
  if (timeZone === "US-Pacific") {
    value = date.toLocaleString("en-US", { timeZone: "America/Los_Angeles", dateStyle: 'short', timeStyle: 'short' }).split(",");
  }
  return (value)
}

export default AllTimeFormats;