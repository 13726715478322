import moment from "moment"

const DateFormatter = (requiredDate) => {
  let dateFullFormatCheck = "";
  const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));
  let requiredDateFormat = hnts_user && hnts_user.settings && hnts_user.settings.date_format ? hnts_user.settings.date_format : "YYYY/MM/DD" ;
  //If Date length is greater thatis if it is with time we will use moment;
  if(requiredDate && requiredDate.length > 0){
    dateFullFormatCheck = moment(requiredDate).format(requiredDateFormat);
  }
  else{  
  dateFullFormatCheck = requiredDate;  
  }
  return (
    dateFullFormatCheck
  )
}

export default DateFormatter