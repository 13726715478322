import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { Container } from "react-bootstrap";

export default class Layout extends React.Component {
  render() {
    return (
      <>
        <Header navigate={useNavigate} />
        <div fluid>{this.props.children}</div>
      </>
    );
  }
}
