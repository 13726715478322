export const validateForm = (formData) =>{
    let errorCheck = {}
    let formDataKeys = Object.keys(formData);
    formDataKeys.forEach((key) => {
        if(formData[key].length <=0){
            errorCheck[key] = true;
        }else{
            errorCheck[key] = false;
        };
    })
    return errorCheck;
}