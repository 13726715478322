import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { LoginUrl } from "../../../Utils/Urls";
import eyeInActive from "../../../assets/images/eye-inactive.png";
import eyeActive from "../../../assets/images/eye-active.png";
import { Link } from "react-router-dom";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";

const regExp = RegExp(/^[\w'-]+(?:\.[\w'-]+){0,2}@[\w.-]+\.[\w-]+$/);

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      type: "password",
      errorMsg: "",
      isError: {
        email: "",
        password: "",
      },
      buttonDisable: false,
      classname: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  // toggle password show/hide icon
  togglePassword = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  // handle form input changes
  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "email":
        if (regExp.test(value)) {
          isError.email = "";
        }
        break;
      case "password":
        if (value.length >= 8) {
          isError.password = "";
        }
        break;
      default:
        break;
    }

    this.setState({
      isError,
      [name]: value,
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    let isError = { ...this.state.isError };
    let isValid = true;
    let isValidEmail = true;
    this.setState({
      loading: true,
      buttonDisable: true,
    });

    if (regExp.test(email)) {
      isError.email = "";
      this.setState({
        isError,
      });
    } else {
      isError.email = "Enter a valid email";
      this.setState({
        isError,
        errorMsg: "Please a enter valid email",
      });
      isValid = false;
      isValidEmail = false;
    }

    if (password.length >= 8) {
      isError.password = "";
      this.setState({
        isError,
      });
    } else {
      isError.password = "Enter a valid password";
      if (isValidEmail) {
        this.setState({
          isError,
          errorMsg: "Please enter a valid password",
        });
      } else {
        this.setState({
          isError,
          errorMsg: "Please enter a valid email and password",
        });
      }
      isValid = false;
    }

    if (isValid) {
      fetch(LoginUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Client-Ip": this.props.ipAddress,
        },
        credentials: "include",
        body: JSON.stringify({
          email: email,
          password: password,
          // ip_address: this.props.ipAddress,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          localStorage.setItem("IPAddress", this.props.ipAddress);
          localStorage.setItem("loggedInRole", json.user.role);
          // if (json.status === 200) {
          localStorage.setItem("authorizationKey", json.key);
          //Cookies.set('my-app-auth',json.access_token );
          localStorage.setItem(
            "hnts_access_token",
            JSON.stringify(json.access_token)
          );
          localStorage.setItem(
            "hnts_refresh_token",
            JSON.stringify(json.refresh_token)
          );
          // localStorage.setItem("sessionId", JSON.stringify(json.sessionId));
          localStorage.setItem("hnts_user", JSON.stringify(json.user));
          let data = { role: "superadmin" };
          localStorage.setItem("htns_user_afterEdit", JSON.stringify(data));
          this.setState({
            loading: false,
            buttonDisable: false,
          });
          //Important code to show PD tab

          let shouldShowPrimaryDetails = "";

          // IsAuthorized("Settings", "pd_employment_status")
          //   ? (shouldShowPrimaryDetails = "employmentstatus")
          //   : 
            IsAuthorized("Settings", "pd_order_status")
              ? (shouldShowPrimaryDetails = "orderStatus")
              : IsAuthorized("Settings", "pd_comment_category")
                ? (shouldShowPrimaryDetails = "commentCategory")
                : IsAuthorized("Settings", "pd_payor_details")
                  ? (shouldShowPrimaryDetails = "payorDetails")
                  : IsAuthorized("Settings", "pd_physician")
                    ? (shouldShowPrimaryDetails = "physician")
                    : IsAuthorized("Settings", "pd_payor_allowable")
                      ? (shouldShowPrimaryDetails = "payorallowable")
                      : IsAuthorized("Settings", "pd_insurance_to_payments")
                        ? (shouldShowPrimaryDetails = "insurancetopayments")
                        : IsAuthorized("Settings", "pd_prod_visit_types")
                          ? (shouldShowPrimaryDetails = "productivityvisittypes")
                          : (shouldShowPrimaryDetails = "");
          localStorage.setItem(
            "primaryDetailsSelectedTab",
            shouldShowPrimaryDetails
          );
          //   this.props.navigate("/");
          window.location.replace("/");
          //}
        })
        .catch((err) => {
          return err.json();
        })
        .catch((error) => {
          if (error.response === undefined) {
            this.setState({
              buttonDisable: false,
              loading: false,
              inValidEmail: false,
              inValidPassword: false,
              // errorMsg: "Connection timed out. Please try again",
              errorMsg: "Enter a valid password",
            });
          } else {
            isError.email = "Enter a valid email";
            isError.password = "Enter a valid password";
            this.setState({
              buttonDisable: false,
              loading: false,
              inValidEmail: true,
              inValidPassword: true,
              errorMsg: "Please enter a valid email and password",
            });
          }
        });
    } else {
      this.setState({
        loading: false,
        buttonDisable: false,
      });
    }
  };

  render() {
    return (
      <div className="form-section">
        <div className="form-header">Login to your Account</div>
        <div className="form-msg">
          {this.state.errorMsg && (
            <div className="login-text-error">{this.state.errorMsg}</div>
          )}
          {!this.state.errorMsg && <div className="login-text-msg" />}
        </div>
        <form onSubmit={this.handleLogin}>
          <div className="form-field">
            <label
              className={
                this.state.isError.email.length > 0 ? "invalid" : "valid"
              }
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="John@gmail.com"
              value={this.state.email}
              onChange={this.handleChange}
              className={
                this.state.isError.email.length > 0
                  ? "is-invalid form-control"
                  : "form-control"
              }
            />
            {this.state.isError.email.length > 0 && (
              <div className="footer-text footer-text-error">
                {this.state.isError.email}
              </div>
            )}
            {this.state.isError.email.length <= 0 && (
              <div className="footer-text">Enter the registered email</div>
            )}
          </div>

          <div className="mt-4" />

          <div className="form-field">
            <label
              className={
                this.state.isError.password.length > 0 ? "invalid" : "valid"
              }
            >
              Password
            </label>
            <input
              type={this.state.type}
              name="password"
              id="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleChange}
              className={
                this.state.isError.password.length > 0
                  ? "is-invalid form-control"
                  : "form-control"
              }
            />
            <div className="eye-icon" onClick={this.togglePassword}>
              {this.state.type === "password" && (
                <img src={eyeInActive} alt="Hide Password" />
              )}
              {this.state.type === "text" && (
                <img src={eyeActive} alt="Show Password" />
              )}
            </div>
            {this.state.isError.password.length > 0 && (
              <div className="footer-text footer-text-error">
                {this.state.isError.password}
              </div>
            )}
            {this.state.isError.password.length <= 0 && (
              <div className="footer-text">Enter the alphanumeric password</div>
            )}
          </div>

          <div className="form-footer-msg"></div>
          <Link className="text-center d-block mb-2" to="/forgot-password">
            Forgot Password?
          </Link>

          {/* {this.state.buttonDisable ?
                        <button className='submit-btn1' disabled={this.state.buttonDisable}>
                            <div className='submit-text'>Login
                                <span>{this.state.loading && (
                                    <Spinner animation="border" variant="white" />
                                )}</span>
                            </div>
                        </button> :
                        <button className='submit-btn' disabled={this.state.buttonDisable}>
                            <div className='submit-text'>Login
                            </div>
                        </button>
                    } */}

          <button className="submit-btn" disabled={this.state.buttonDisable}>
            <div className="submit-text">
              Login{"\u00a0\u00a0"}
              <span>
                {this.state.loading && (
                  <Spinner
                    animation="border"
                    variant="white"
                    className="login-spinner"
                  />
                )}
              </span>
            </div>
          </button>
        </form>
      </div>
    );
  }
}
