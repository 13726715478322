import React from 'react'
import "./dots.css";

const DotsLoader = ({ height = "vh-100" }) => {
    return (
        <div className={`${height} loading-dots`}>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
        </div>
    )
}

export default DotsLoader