export const handleNullValues = (value) => {
  return value !== "Nan" &&
    value !== "nan" &&
    value !== "NaN" &&
    value !== "None" &&
    value !== "none" &&
    value !== "NaT" &&
    value !== "Nat" &&
    value !== "nat" &&
    value !== "null"
    ? value
    : "";
};
