import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'
import ForgotPasswordForm from './forgotPasswordForm.js';
import vigeoDash from "../../../assets/images/VigeoDashLogo.svg"
import frame from '../../../assets/images/frame.png';
import healthcare from '../../../assets/images/healthcare.png';
import background from '../../../assets/images/background.svg';
import '../../../assets/css/Authentication.css';

function ForgotPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div className='fill-height'>
      <Container fluid className='forgot-pwd-section'>
        <div className='header-section'>
          <div className='logo-section'>
            <img src={vigeoDash} className="site-logo" alt="logo" />
          </div>
        </div>

        <div className='content'>
          <div className='top-container'>
            <Row>
              <Col md="12">
                <div className='frame-section'>
                  <div className='frame-header'>Homecare Clinical Dashboard</div>
                  <div className='frame-subline'>Track and update all at one place</div>
                  <div className='body-container'>
                    <Row>
                      <Col md="6" className='d-none d-lg-block'>
                        <div className='frame-img'>
                          <img src={frame} alt="Frame" />
                        </div>
                      </Col>
                      <Col md="6"><ForgotPasswordForm /></Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <div className='powered-by'>
                <div className='powered-by-text'>Powered by</div>
                <div className='powered-by-img'><img src={healthcare} alt="Frame" /></div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='background-img'><img src={background} alt="" /></div>
      </Container>
    </div>
  )
}

export default ForgotPassword;