// Dev Base Url
// export const hostUrl = "https://staging-api.vigeodash.com/";


// Development Base Url
// export const hostUrl = "https://development-api.vigeodash.com/";

// Prod Base Url
export const hostUrl = "https://hnts-api.vigeodash.com/";

/*Comments common urls */
export const dashboardKPIsUrl = hostUrl + "accounts/daily_operational_kpi/";
export const UpdateUserSessionTokenUrl =
  hostUrl + "accounts/auth/update_user_token/";

export const creatCommentsCommonUrl = hostUrl + "common/create_comments/";
export const getCommentsCommonUrl = hostUrl + "common/fetch_comments/";
export const fetchCommentCategoriesUrl = hostUrl + "common/fetch_categories/";
export const fetchAssignedUsersUrl = hostUrl + "common/fetch_department_users/";

export const patientResponsibilityUrl =
  hostUrl + "patient_responsibility/patient_responsibility_homescreen/";

export const patientResponsibilityOverFlowUrl =
  hostUrl + "/patient_responsibility/view_payment_overflows/";

export const patientResponsibilityViewClaimUrl =
  hostUrl + "patient_responsibility/patient_responsibility_view_claims/";

export const patientResponsibilityViewPaymentUrl =
  hostUrl + "patient_responsibility/view_payments/";

export const getPdfEOBURL = hostUrl + "fetch_eob_doc_v1/";

export const cipherRulesURL = hostUrl + "accounts/fetch_rules/";

export const paymentsCrudUrl =
  hostUrl + "patient_responsibility/crud_payments/";

export const generateInvoiceUrl =
  hostUrl + "patient_responsibility/generate_invoicescreen/";

export const viewResendsUrl =
  hostUrl + "patient_responsibility/view_resend_invoices/";

export const sendInvoiceUrl =
  hostUrl + "patient_responsibility/send_invoice_screen/";

export const viewInvoicePDFUrl =
  hostUrl + "patient_responsibility/view_invoice/";

export const pdfdownloadLink = hostUrl + "static/patient_eligibility_pdfs/";
export const fetchoutstandingUrl =
  hostUrl + "patient_responsibility/fetch_outstanding/";

export const addPaymentsUrl = hostUrl + "patient_responsibility/add_payments/";

export const resetDataUrl =
  hostUrl + "patient_responsibility/initial_insert_patient_responsibility/";

export const excel_download_url = hostUrl + "accounts/excel_convert/";
export const csv_download_url = hostUrl + "static/patient_eligibility_pdfs/";
export const data_plyground_url =
  hostUrl + "miscellaneous/graph_n_tbl_data_playground/";
export const table_names_url =
  hostUrl + "miscellaneous/dropdown_dataplayground/";
export const pivot_table_url =
  hostUrl + "miscellaneous/excel_convert/";
export const view_tree_names_url =
  hostUrl + "miscellaneous/dropdown_view_tree/";
export const get_tree_data_url = hostUrl + "miscellaneous/view_tree/";
export const tblDataPlaygroundUrl = hostUrl + "accounts/tbl_dataplayground/";
export const graphdataplaygroundUrl =
  hostUrl + "accounts/graph_dataplayground/";
export const graphdataplaygroundExportUrl = hostUrl + "accounts/excel_convert/";
export const graphdataplaygroundExportRandomNumUrl =
  hostUrl + "static/patient_eligibility_pdfs/";

export const DataPlayListUrl = hostUrl + "accounts/dropdown_dataplayground/";
export const oldDenialsURL = hostUrl + "denials/denial_claims/";
export const oldFetchChildRows = hostUrl + "denials/fetch_child_rows/";

export const DenialSyncURL = hostUrl + "data_sync/denied_claim_sync/";
export const DenialCliaimsURL = hostUrl + "denials/denial_homescreen/";
export const EDIDenialCliaimsURL = hostUrl + "denials/edi/denial_homescreen/";
export const DefaultFiltersForDenial = hostUrl + "denials/denial_filters/";
export const EDIDefaultFiltersForDenial =
  hostUrl + "denials/edi/denial_filters/";
export const DenialChildRowsUrl = hostUrl + "denials/fetch_denial_visits/";
export const EDIDenialChildRowsUrl =
  hostUrl + "denials/edi/fetch_denial_visits/";

export const DenialCliaimsExportFileUrl =
  hostUrl + "static/patient_eligibility_pdfs/";
export const DenialCliaimsGetCommentsResultsUrl =
  hostUrl + "denials/get_comments/";
export const DenialCliaimsPostCommentsResultsUrl =
  hostUrl + "denials/add_comments/";
export const insuranceNamesURL = hostUrl + "denials/fetch_insurance_names/";
export const downloadPDFUrl = hostUrl + "data_sync/download_the_pdf/";
export const getDeniedDropdDownsUrl =
  hostUrl + "denials/populate_dropdown_values/";

export const AutoPostingBitmapUrl = hostUrl + "auto_posting/html_table/";
export const AutoPostingMatchedResultsUrl = hostUrl + "auto_posting/";
export const UBO4AutoPostingResultsUrl = hostUrl + "auto_posting/ch_ubo4/";
export const EOBAutoPostingResultsUrl = hostUrl + "auto_posting/ch_eob/";
export const AutoPostingGetCommentsResultsUrl =
  hostUrl + "auto_posting/get_comments/";
export const AutoPostingPostCommentsResultsUrl =
  hostUrl + "auto_posting/add_comments/";
export const AutoPostingSync = hostUrl + "data_sync/sync_autoposting";
export const MatchingColumnsLists = hostUrl + "auto_posting/matching_columns/";

export const ManualSync = hostUrl + "data_sync/update_data";
export const LogUIPageRenderPerformanceUrl =
  hostUrl + "accounts/log_ui_page_render_performance/";
export const LogUIComponentRenderPerformanceUrl =
  hostUrl + "accounts/log_ui_component_render_performance/";
export const LoginUrl = hostUrl + "accounts/auth/login/";
export const LogoutURL = hostUrl + "accounts/auth/logout/";
export const registerUserUrl = hostUrl + "accounts/auth/registration/";
export const getDateUrl = hostUrl + "tablepages/last_load/";
export const getDateUrl1 = hostUrl + "accounts/get_last_sync/";
export const changePassword = hostUrl + "accounts/auth/password/change/";
export const EMRConnectivityUrl =
  hostUrl + "accounts/check_snowflake_connection/";
export const primaryTablesUrl = hostUrl + "dynamic_tables/dynamic_tables/";
export const pageNamesUrl = hostUrl + "dynamic_tables/fetch_page_names/"
export const primaryInsNamesUrl = hostUrl + "dynamic_tables/fetch_payor_insurance/"
export const getPrimaryDetailsIDS = hostUrl + "common/fetch_ids/";
export const getStaffDropDowns = hostUrl + "dynamic_tables/staff_dropdowns/"
export const settingManagesURL = hostUrl + "dynamic_tables/manage_staff_statuses_or_rates/";
export const updatePassword = hostUrl + "accounts/auth/password/change/";
export const updateCompanyDetailsUrl =
  hostUrl + "accounts/update_company_details/";
export const tenantSettingsUrl = hostUrl + "accounts/update_tenant_settings/";
export const integrationCredentialsUrl = hostUrl + "accounts/creds/";
export const userActivityUrl = hostUrl + "accounts/user_activity/";
export const userIDsUrl = hostUrl + "accounts/user_listing/";
export const tenantIdUrl = hostUrl + "accounts/get_tenant_settings/";
export const companyDetailsUrl = hostUrl + "accounts/get_company_details/";
export const companyLogoUpdateUrl = hostUrl + "accounts/get_logo/";
export const changePresentPasswordUrl =
  hostUrl + "accounts/update_pwd_for_a_user/";
/*-------------------------------BILLING---------------------------------------------*/
export const billingHomeScreenUrl = hostUrl + "billing/billing_homescreen/";
export const ediBillingHomeScreenUrl =
  hostUrl + "billing/edi/billing_homescreen/";
export const billingAccordBodyURL =
  hostUrl + "billing/billing_child_homescreen/";
export const ediBillingAccordBodyURL =
  hostUrl + "billing/edi/billing_child_homescreen/";
export const billingSecondScreenUrl =
  hostUrl + "billing/billing_service_and_status_provider/";
export const ediBillingSecondScreenUrl =
  hostUrl + "billing/edi/billing_service_and_status_provider/";
export const billingThirdScreenUrl = hostUrl + "billing/patient_claims/";
export const billingThirdScreenExportUrl =
  hostUrl + "billing/patient_episodes_export/";
export const edibillingThirdScreenUrl = hostUrl + "billing/edi/patient_claims/";
export const edibillingThirdScreenExportUrl =
  hostUrl + "billing/edi/patient_episodes_export/";
export const providerDropDownURL = hostUrl + "billing/ins_code_and_provider/";

export const billingBatchURL = hostUrl + "billing/billing_batch_report/";
export const remittancesURL =
  hostUrl + "billing/billing_remittance_by_insurance/";
export const toBeBilledURL = hostUrl + "billing/to_be_billed_report/";
export const remittancesOptionsURL =
  hostUrl + "billing/billing_remittance_insurance_addr/";
export const billingVSRemittanceURL =
  hostUrl + "billing/billing_versus_remittance/";
export const agedARUrl = hostUrl + "billing/billing_aged_report/";
export const agedARCostReportUrl =
  hostUrl + "billing/billing_aged_cost_report/";

/*-------------------------------ORDERS---------------------------------------------*/
export const pendingOrdersUrl =
  hostUrl + "pending_orders/pending_orders_cards/";
export const holdingBillingUrl = hostUrl + "pending_orders/holding_billing/";
export const notHoldingBillingUrl =
  hostUrl + "pending_orders/not_holding_billing/";
export const deadlineUrl = hostUrl + "pending_orders/deadline/";
export const byPassedUrl = hostUrl + "pending_orders/bypassed/";
export const InhouseProcessingUrl = hostUrl + "pending_orders/inhouse_data/";
export const ToBeSentUrl = hostUrl + "pending_orders/tobesent_data/";
export const PendingSignaturesUrl =
  hostUrl + "pending_orders/pending_signatures_data/";
// export const PhysicianLoadUrl = hostUrl + "pending_orders/physician_load/";
export const PhysicianLoadUrl = hostUrl + "pending_orders/physician_load/";
export const PhysicianLoadPendingSignatureUrl =
  hostUrl + "pending_orders/pending_signatures_physician_load/";
/*----------------------------Referrals-------------------------------------------*/

export const ReferralsScreenUrl =
  hostUrl + "referral_management/referral_management_homescreen/";
export const ReferralsCurrentUrl =
  hostUrl + "referral_management/referral_management_current/";
export const ReferralsPastUrl =
  hostUrl + "referral_management/referral_management_past/";
export const ReferralPhysicianLoadPastUrl =
  hostUrl + "pending_orders/referral/inhouse_physician_load_past/";
export const ReferralPhysicianLoadCurrentUrl =
  hostUrl + "pending_orders/referral/inhouse_physician_load_current/";
export const ReferralPendingSignaturePastUrl =
  hostUrl + "pending_orders/referral/pending_signatures_physician_load_past/";
export const ReferralPendingSignatureCurrentUrl =
  hostUrl +
  "pending_orders/referral/pending_signatures_physician_load_current/";
/*----------------------------Per Visit Management-------------------------------------------*/
export const pervisitmanagementUrl =
  hostUrl + "per_visit_management/per_visit_screen/";
export const pervisitmanagementPastUrl =
  hostUrl + "per_visit_management/per_visit_past/";
/*-------------------------------RCD---------------------------------------------*/

// RCD / rcd_export /

export const RCDExportUrl = hostUrl + "RCD/rcd_export/";
export const RCDListingUrl = hostUrl + "RCD/RCD_listing/";

// export const RCDToBeSentUrl = hostUrl + "RCD/tobesent/";
export const RCDAwaitingUrl = hostUrl + "RCD/awaiting/";
export const RCDAwaitingUploadDocUrl = hostUrl + "RCD/upload_pdf_to_s3/";
// export const RCDAffirmedUrl = hostUrl + "RCD/affirmed/";
export const RCDAffirmedDownloadDocUrl = hostUrl + "RCD/read_pdf_file_from_s3/";
export const RCDRejectedUrl = hostUrl + "RCD/rejected/";
export const RCDToBeSentRecordUrl = hostUrl + "RCD/tobesent_update/";
// export const RCDToBeSentUpdateUrl = hostUrl + "RCD/edit_tobesent/";
export const RCDToBeSentRecordUpdateUrl = hostUrl + "data_sync/sync_rcd/";
// export const RCDAffirmedUpdateUrl = hostUrl + "RCD/awaiting_update/";
export const RCDAffirmedGetDataUrl = hostUrl + "RCD/get_details/";
export const RCDAffirmedUpdateDataUrl = hostUrl + "RCD/edit_affirmed/";
// export const RCDcategorycounts = hostUrl + "RCD/category_counts/";
/*-------------------------------Patient Eligibility---------------------------------------------*/
// export const patientEligibilityUrl = "https://demo-api.vigeodash.com/tablepages/patient_eligibility"
export const patientEligibilityUrl =
  hostUrl + "patient_eligibility/get_data/";
export const autoFillFormUrl =
  hostUrl + "patient_eligibility/autofill_form/";
// export const patientEligibilityUrl = hostUrl + "patient_eligibility/patient_eligibility_dummy"
export const patientEligibilityDocumentUrl =
  hostUrl + "patient_eligibility/get_file_from_s3/";
export const patientEligibilityHistoryUrl =
  hostUrl + "patient_eligibility/get_history/";
export const patientEligibilitySubmitUrl =
  hostUrl + "patient_eligibility/check_eligibility/";
export const PatientEligibilityPdfUrl =
  hostUrl + "static/patient_eligibility_pdfs/";

/*-------------------------------CLAIM COMMENTS---------------------------------------------*/
export const claimDetailsUrl = hostUrl + "billing/claim_details/";
export const EDIClaimDetailsUrl = hostUrl + "billing/edi_claim_details/";
export const claimDetailsPageUrl = hostUrl + "billing/claim_details_page/";
// export const creatClaimCommentUrl = hostUrl + "billing/claim_comments_update/";
// export const getClaimCommentUrl = hostUrl + "billing/claims_comments_retrieve/";
export const episodeDetailsUrl = hostUrl + "billing/episode_details_page/";
export const EDIEpisodeDetailsUrl =
  hostUrl + "billing/edi_episode_details_page/";
// Clinicial dropdown Url
/*-------------------------------LUPA RISK-------------------------------------------------*/
export const lupaRiskUrl = hostUrl + "tablepages/lupa_risk/";
export const lupaRiskcurrentUrl = hostUrl + "tablepages/lupa_risk/";

export const refreshTokenUrl = hostUrl + "accounts/auth/api/token/refresh/";
/*-------------------------------SCHEDULE DEVIATIONS---------------------------------------*/
export const scheduledeviationUrl = hostUrl + "tablepages/schedule_deviation/";
/*-------------------------------INCORRECT MRNS--------------------------------------------*/
export const incorrectmrnsUrl = hostUrl + "tablepages/incorrect_mrns/";
/*-------------------------------BLANK HIPPS-----------------------------------------------*/
export const blankHippsUrl = hostUrl + "tablepages/blank_hipps/";
export const blankHippsDetailsUrl =
  hostUrl + "tablepages/filled_hipps_details/";
export const UpdateBlankHippsUrl = hostUrl + "tablepages/update_HIPPS/";
export const SyncBlankHippsUrl = hostUrl + "data_sync/sync_blank_hipps";
/*-------------------------------DISCHARGE PLANNING-----------------------------------------*/
export const dischargePlanningUrl = hostUrl + "tablepages/discharge_planning/";
/*-------------------------------EPISODE VISIT----------------------------------------------*/
export const EpisodevisitAuthSummaryUrl =
  hostUrl + "tablepages/episode_visit_summary/";
/*-------------------------------PRODUCTIVITY-----------------------------------------------*/
export const ProductivityReportsListing =
  hostUrl + "productivity/productivity_reports/";
export const ProductivityValueListing =
  hostUrl + "productivity/productivity_value_lists/";
export const ProductivityTrendsUrl =
  hostUrl + "productivity/productivity_trends/";
export const ProductivityTrendsGroupUrl =
  hostUrl + "productivity/productivity_trends/";
export const ProductivityUsersUrl =
  hostUrl + "productivity/productivity_users/";
export const positionGroupUrl =
  hostUrl + "productivity/productivity_value_lists/";
export const getPayPeriodDate = hostUrl + "productivity/get_payperiod_date/";
/*-------------------------------PENDING TASK-----------------------------------------------*/
export const PendingTaskUrl = hostUrl + "pendingtasks/past_due_visits/";
export const PendingTaskCurrentUrl =
  hostUrl + "pendingtasks/pending_tasks_current/";
export const getClinicians = hostUrl + "dynamic_tables/get_active_clinicians/";
export const sendEmailUrl = hostUrl + "pendingtasks/send_email/";
/*-------------------------------CENSUS-----------------------------------------------------*/
export const censusFirstTabURL = hostUrl + "census/census_tab_1/";
export const censusHistoricGraphURL = hostUrl + "census/census_historic_graph/";
export const CensusTrendsGraph = hostUrl + "census/census_trend_by_category/";
export const CensusTaskCategoryUrl =
  hostUrl + "census/census_tab_2_task_category/";
export const censusSecondtabURL = hostUrl + "census/census_oasis_events/";
export const CensusDetailsUrl = hostUrl + "census/census_details_info/";
/*-------------------------------PCC CENSUS-------------------------------------------------*/
export const PCCCensusDetailsUrl = hostUrl + "pcc_census/pcc_census/";
export const getPCCCensusURL = hostUrl + "pcc_census/pcc_census_screen/";
export const generateCOCURL = hostUrl + "pcc_census/pdf_gen/";
/*-------------------------------VISIT PLANNING---------------------------------------------*/

export const pdgmManagementUrl =
  hostUrl + "visitplanning/clinical/pdgm_management/";

export const getVisitPlanningUrl =
  hostUrl + "visitplanning/visit_planning_data/";
export const getVisitPlanningCurrentUrl =
  hostUrl + "visitplanning/visit_planning_current/";
/*-------------------------------PROVIDER PROFITABILITY-------------------------------------*/
export const ProviderProfitabilityUrl =
  hostUrl + "provider_profitability/provider_profitability/";
export const DefaultFiltersForProviderProfitability =
  hostUrl + "provider_profitability/get_filters/";
/*-------------------------------ACCOUNT---------------------------------------------*/
export const getUsersUrl = hostUrl + "accounts/get_all_users";
export const UsersExportURL = hostUrl + "accounts/user/data_export/";
export const userSessionUrl = hostUrl + "accounts/user/get_session/";
export const deleteUserUrl = hostUrl + "accounts/delete_user/";
export const putUserUrl = hostUrl + "accounts/edit_user/";
export const patchUserDetails = hostUrl + "accounts/edit_user_profile/";
export const commentCategoriesUrl =
  hostUrl + "accounts/categories_in_comments/";
export const billingUsersUrl = hostUrl + "accounts/billing_users/";
//ADMIN
/*-------------------------------AUTHORIZATIONS----------------------------------------------*/
export const AuthorizationListing = hostUrl + "tablepages/authorizations/";
export const EmailRedirect = hostUrl + "accounts/auth/password_reset";
export const PasswordResetURL =
  hostUrl + "accounts/password-reset-confirm";

export const mainExportURL = hostUrl + "static/patient_eligibility_pdfs/";

// Release history urls
export const releaseNotesListingUrl = hostUrl + "miscellaneous/release_note/";
// export const releaseNotesListingUrl = "https://athenadevapi.vigeodash.com/" + "miscellaneous/release_note/";

export const syncHistoryUrl = hostUrl + "data_sync/get_sync_details/";

export const trainingManualListingUrl =
  hostUrl + "training_manuals/help_screen/";
