import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IsAuthorized } from '../../../Common/utilsFunctions/IsAuthorized';
import { AxiosInstance } from '../../../Components/Util_Functions/AxiosInstance';
import { pageNamesUrl, primaryTablesUrl } from '../../../Utils/Urls';
import { handleNullValues } from '../../../Common/utilsFunctions/handleNullValues';
import { validateForm } from '../../../Common/FormTemplates/callToActions';
import uppercaseLabels from '../../../Common/utilsFunctions/UpperCaseLabels';


const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
 const DataTable = lazy(() => import("../../../Common/AgGrid/DataTable"));
const TextFiledComponent = lazy(() =>
    import("../../../Common/FormTemplates/TextFiledComponent")
);
const LoaderComponent = lazy(() => import("../../../Common/LoaderComponent"));
const ToasterComponet = lazy(() => import("../../../Common/ToasterComponet"));
const DotsLoader = lazy(() => import("../../../Common/DotsLoader"));
const SelectDropDown = lazy(() => import("../../../Common/SelectDropDown"));
const Authorizations = () => {
    const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));
    const navigate = useNavigate();
    /*BreadCrumb States */
    const [submitSpinner, setSubmitSpinner] = useState(false);

    /*Top dropdown states */
    const [pageNameOptions, setPageNameOptions] = useState([]);
    const [pageNameValue, setPageNameValue] = useState({ label: "ALL", value: "ALL" });
    // toast message states
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState("");
    const [toastColor, setToastColor] = useState("");

    const [addRecordModalPopUp, setAddRecordModalPopUp] = useState(false);
    const [editRecordModalPopUp, setEditRecordModalPopUp] = useState(false);
    const [deleteRecordModalPopUp, setDeleteRecordModalPopUp] = useState(false);

    // add record form state and error state
    const roleOptions = [
        {
            label: "PLATFORM ADMIN",
            value: "platform_admin",
        },
        {
            label: "PLATFORM USER",
            value: "platform_user",
        },
        {
            label: "APPLICATION ADMIN",
            value: "application_admin",
        },
        {
            label: "APPLICATION USER",
            value: "application_user",
        },
        {
            label: "ACCOUNT ADMIN",
            value: "account_admin",
        },
        {
            label: "MULTI DEPARTMENT MANAGER",
            value: "multi_department_manager",
        },
        {
            label: "DEPARTMENT MANAGER",
            value: "department_manager",
        },
        {
            label: "DEPARTMENT USER",
            value: "department_user",
        },
    ];
    const options = [
        { label: "YES", value: "yes" },
        { label: "NO", value: "no" }
    ];
    const options1 = [
        { label: "None", value: "" },
        { label: "YES", value: "yes" },
        { label: "NO", value: "no" }
    ];
    const [role, setRole] = useState({
        label: "select role", value: ""
    });
    const [screen, setScreen] = useState({
        label: "YES", value: "yes"
    });
    const [cta, setCta] = useState({
        label: "None", value: ""
    });
    const [exportState, setExportState] = useState({
        label: "None", value: ""
    });
    const [masking, setMasking] = useState({
        label: "None", value: ""
    });
    const [comments, setComments] = useState({
        label: "None", value: ""
    });
    const [addRecordState, setAddRecordState] = useState({
        page: "",
        role: "",
        screen: screen?.value,
        exportState: exportState?.value,
        cta: cta?.value,
        masking: masking?.value,
        comments: comments?.value
    });

    /*Validations state for form */
    const [addFormErrors, setAddFormErros] = useState({
        page: false,
        department: false,
    });

    // edit record form state and error state

    const [editRole, setEditRole] = useState({
        label: "select role", value: ""
    });
    const [editScreen, setEditScreen] = useState({
        label: "YES", value: "yes"
    });
    const [editCta, setEditCta] = useState({
        label: "None", value: ""
    });
    const [editExportState, setEditExportState] = useState({
        label: "None", value: ""
    });
    const [editMasking, setEditMasking] = useState({
        label: "None", value: ""
    });
    const [editComments, setEditComments] = useState({
        label: "None", value: ""
    });
    const [editRecordState, setEditRecordState] = useState({
        page: "",
        editRole: "",
        editScreen: screen?.value,
        editExportState: exportState?.value,
        editCta: cta?.value,
        editMasking: masking?.value,
        editComments: comments?.value
    });

    /*Validations state for form */
    const [editFormErrors, setEditFormErros] = useState({
        page: false,
        department: false,
    });

    const [deleteRecordState, setDeleteRecordState] = useState("");

    // table data statee
    const [tableData, settableData] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);

    // Datatable header configurations
    const [columnData, setColumnData] = useState([
        {
            field: "page",
            headerName: "PAGE",
            checked: true,
            suppressMovable: true,
            minWidth: 200,
        },
        {
            field: "role_and_department",
            headerName: "ROLE AND DEPARTMENT",
            checked: true,
            suppressMovable: true,
            minWidth: 280,
        },
        {
            field: "screen",
            headerName: "SCREEN",
            checked: true,
            minWidth: 140,
        },
        {
            field: "cta",
            headerName: "CTA",
            checked: true,
            suppressMovable: true,
            minWidth: 140,
            maxWidth: 140,
        },
        {
            field: "export",
            headerName: "EXPORT",
            checked: true,
            suppressMovable: true,
            minWidth: 140,
            maxWidth: 140,
        },
        {
            field: "masking",
            headerName: "MASKING",
            checked: true,
            minWidth: 140,
            maxWidth: 140,
        },
        {
            field: "comments",
            headerName: "COMMENTS",
            checked: true,
            minWidth: 200,
            maxWidth: 140,
        },
    ]);

    const showAddRecordModal = () => {
        setAddRecordModalPopUp(true);
    };

    /*Function which handles add record modal */
    const hideAddRecordModal = () => {
        setAddRecordModalPopUp(false);
        setAddRecordState({
            page: "",
            role: "",
            screen: "",
            cta: "",
            exportState: "",
            masking: "",
            comments: ""
        });
        setAddFormErros({});
        setRole({ label: "select role", value: "" });
        setScreen({
            label: "YES", value: "yes"
        });
        setCta({
            label: "None", value: ""
        });
        setExportState({
            label: "None", value: ""
        });
        setMasking({
            label: "None", value: ""
        });
        setComments({
            label: "None", value: ""
        });
    };

    const buttonsToShow = [
        {
            buttonName: "Add New Record",
            buttonClick: showAddRecordModal,
            disabled: false,
        },
    ];

    // toggle edit record popup modal functions
    const showEditRecordModal = (params) => {
        const editData = params.data;
        setEditRecordState({
            id: editData.id,
            page: editData?.page !== "--" ? editData.page : "",
            editRole: editData?.role_and_department !== " --" ? editData?.role_and_department : "",
            editScreen: editData?.screen !== "--" ? editData.screen : "",
            editCta: editData?.cta !== "--" ? editData.cta : "",
            editExportState: editData?.export !== "--" ? editData.export : "",
            editMasking: editData?.masking !== "--" ? editData.maksing : "",
            editComments: editData?.comments !== "--" ? editData?.comments : ""
        });
        let { role_and_department,
            screen,
            cta,
            masking,
            comments } = editData;

        setEditRole({
            label: role_and_department.toUpperCase(),
            value: role_and_department
        })
        setEditScreen({
            label: screen.toUpperCase(),
            value: screen
        });
        setEditCta({
            label: cta !== "--" ? cta.toUpperCase()
                : "None", value: cta
        });
        setEditExportState({
            label: editData?.export !== "--" ? editData.export?.toUpperCase() : "None",
            value: editData?.export
        });
        setEditMasking({
            label: masking !== "--" ? masking?.toUpperCase() : "None",
            value: masking
        });
        setEditComments({
            label: comments !== "--" ? comments.toUpperCase() : "None",
            value: comments
        })

        setEditRecordModalPopUp(true);
    };

    /*Hides Edit modal */
    const hideEditRecordModal = () => {
        setEditRecordModalPopUp(false);
    };

    const showDeleteRecordModal = () => {
        setDeleteRecordModalPopUp(true);
    };

    const hideDeleteRecordModal = () => {
        setDeleteRecordModalPopUp(false);
        setDeleteRecordState("");
    };

    const deleteRecord = (data) => {
        showDeleteRecordModal();
        const { id } = data;
        setDeleteRecordState(id);
    };


    /*Add new record button handling function */
    const handleAddNewRecord = (e) => {
        e.preventDefault();
        setSubmitSpinner(true);

        let errors = validateForm(addRecordState);
        let isEmptyData = false;
        console.log(errors)
        Object.keys(errors)?.map((value) => {
            if (["page", "role", "screen"].includes(value)) {
                return errors[value] === true ? isEmptyData = true : "";
            }
            return isEmptyData
        });
        setAddFormErros(errors);

        if (!isEmptyData) {
            let metaData1 = {
                database: "hnts",
                schema: "public",
                table_name: "pd_authorization_details",
                action: "create",
                id: "",
                user_created: hnts_user.pk,
                user_modified: hnts_user.pk,
            };

            let data = {
                "page": addRecordState?.page,
                "role_and_department": addRecordState?.role,
                "access_controls":
                    `{\"screen\": \"${addRecordState?.screen}\", \"export\": \"${addRecordState?.exportState}\", \"cta\": \"${addRecordState?.cta}\", \"comments\": \"${addRecordState?.comments}\", \"masking\": \"${addRecordState?.masking}\"}`
            };
            let dataToBeSend = { meta: metaData1, data: data };
            AxiosInstance.post(primaryTablesUrl, dataToBeSend, {
                customHeaders: {
                    'Screen-Name': 'Settings',
                    "Screen-Section": "Authorizations",
                    "Screen-Component": "Create Authorizations",
                }
            })
                .then((response) => {
                    setShowToaster(true);
                    setToastColor("success");
                    setToasterMessage(response?.data?.Response);
                    setAddRecordState({
                        page: "",
                        role: "",
                        screen: "",
                        cta: "",
                        exportState: "",
                        masking: "",
                        comments: ""
                    });
                    setAddFormErros({});
                    setRole({ label: "select role", value: "" });
                    setScreen({
                        label: "YES", value: "yes"
                    });
                    setCta({
                        label: "None", value: ""
                    });
                    setExportState({
                        label: "None", value: ""
                    });
                    setMasking({
                        label: "None", value: ""
                    });
                    setComments({
                        label: "None", value: ""
                    });
                    getDetailsFromAPI();
                })
                .catch(async (error) => {
                    setShowToaster(true);
                    setToastColor("danger");
                    setToasterMessage(error.response.data.Response);
                })
                .finally(() => {
                    setSubmitSpinner(false);
                    hideAddRecordModal();
                });
        } else {
            setSubmitSpinner(false);
        }
    };

    /*This function triggers when we click on edit in the modal */
    const handleEditNewRecord = (e) => {
        e.preventDefault();
        setSubmitSpinner(true);

        let errors = validateForm(editRecordState);
        let isEmptyData = false;
        Object.keys(errors)?.map((value) => {
            if (["page", "role", "screen"].includes(value)) {
                return errors[value] === true ? isEmptyData = true : "";
            }
            return isEmptyData
        });
        setAddFormErros(errors);

        if (!isEmptyData) {
            let metaData1 = {
                database: "hnts",
                schema: "public",
                table_name: "pd_authorization_details",
                action: "update",
                id: editRecordState?.id,
                user_created: hnts_user.pk,
                user_modified: hnts_user.pk,
            };

            let data = {
                "page": editRecordState?.page,
                "role_and_department": editRecordState?.editRole,
                "access_controls":
                    `{\"screen\": \"${editRecordState?.editScreen}\", \"export\": \"${editRecordState?.editExportState}\", \"cta\": \"${editRecordState?.editCta}\", \"comments\": \"${editRecordState?.editComments}\", \"masking\": \"${addRecordState?.masking}\"}`
            };
            let dataToBeSend = { meta: metaData1, data: data };
            AxiosInstance.post(primaryTablesUrl, dataToBeSend, {
                customHeaders: {
                    'Screen-Name': 'Settings',
                    "Screen-Section": "Authorizations",
                    "Screen-Component": "Update Authorizations",
                }
            })
                .then((response) => {
                    setShowToaster(true);
                    setToastColor("success");
                    setToasterMessage(response.data.Response);
                    getDetailsFromAPI();
                })
                .catch(async (error) => {
                    setShowToaster(true);
                    setToastColor("danger");
                    setToasterMessage(error.response.data.Response);
                })
                .finally(() => {
                    setSubmitSpinner(false);
                    hideEditRecordModal();
                });
        } else {
            setSubmitSpinner(false);
        }
    };

    /*Delete API Hitiing function */
    const handleDeleteNewRecord = (e) => {
        e.preventDefault();
        setSubmitSpinner(true);
        let metaData1 = {
            database: "hnts",
            schema: "public",
            table_name: "pd_authorization_details",
            action: "delete",
            id: deleteRecordState,
            user_created: hnts_user.pk,
            user_modified: hnts_user.pk,
        };
        let data = {};
        let dataToBeSend = { meta: metaData1, data };
        AxiosInstance.post(primaryTablesUrl, dataToBeSend, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "Authorizations",
                "Screen-Component": "Delete Authorizations",
            }
        })
            .then((response) => {
                setShowToaster(true);
                setToastColor("success");
                setToasterMessage(response.data.Response);
                let removeDeletedRow = tableData.filter(
                    (item, index) => item.id !== deleteRecordState && item
                );
                settableData(removeDeletedRow);
            })
            .catch(async (error) => {
                setShowToaster(true);
                setToastColor("danger");
                setToasterMessage(error.response.data.Response);
            })
            .finally(() => {
                setSubmitSpinner(false);
                hideDeleteRecordModal();
                setDeleteRecordState("");
            });
    };

    /*Function to get table details */
    const getDetailsFromAPI = () => {
        setIsTableLoading(true);
        let metaData1 = {
            database: "hnts",
            schema: "public",
            table_name: "pd_authorization_details",
            action: "list",
            id: "",
            user_created: hnts_user.pk,
            user_modified: hnts_user.pk,
        };
        let data1 = {};
        let dataToBeSend = { meta: metaData1, data: data1 };
        if (pageNameValue?.value !== "ALL") {
            dataToBeSend = { ...dataToBeSend, "filters": [{ "colname": "page", "isin": [pageNameValue?.value] }] }
        }
        AxiosInstance.post(primaryTablesUrl, dataToBeSend, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "Authorizations",
                "Screen-Component": "Get Authorizations",
            }
        })
            .then((response) => {
                var results = response?.data?.Response?.data
                    ? response.data.Response.data.map((item, index) => ({
                        id: item.id,
                        role_and_department: handleNullValues(item.role_and_department) ?
                            item.role_and_department : "--",
                        page: handleNullValues(item?.page) ? item?.page : "--",
                        screen: handleNullValues(item?.screen) ? (item?.screen).toUpperCase() : "--",
                        cta: handleNullValues(item?.cta) ? item?.cta.toUpperCase() : "--",
                        export: handleNullValues(item?.export) ? item?.export.toUpperCase() : "--",
                        masking: handleNullValues(item?.masking) ? item?.masking.toUpperCase() : "--",
                        comments: handleNullValues(item?.comments) ? item?.comments.toUpperCase() : "--",
                    }))
                    : settableData([]);
                settableData(results);
                setIsTableLoading(false);
            })
            .catch(async (error) => {
                settableData([]);
                setIsTableLoading(false);
            });
    };

    useEffect(() => {
        // if (IsAuthorized("Settings", "pd_order_status_crud")) {
        setColumnData([
            ...columnData,
            {
                field: "Action",
                headerName: "ACTION",
                resizable: false,
                pinned: "right",
                filter: false,
                sortable: false,
                minWidth: 150,
                maxWidth: 150,
                cellRenderer: (params) => (
                    <div className="d-flex justify-content-around">
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                showEditRecordModal(params);
                            }}
                            bsPrefix="app-primary-color px-2 fs-12 btn btn-link link-underline link-underline-opacity-0 fw-bold"
                        >
                            Edit
                        </Button>

                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                deleteRecord(params.data);
                            }}
                            bsPrefix="text-danger px-2 fs-12 btn btn-link link-underline link-underline-opacity-0 fw-bold"
                        >
                            Delete
                        </Button>
                    </div>
                ),
                checked: true,
                suppressMovable: true,
            },
        ]);
        // }

        AxiosInstance.get(pageNamesUrl, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "Authorizations",
                "Screen-Component": "Fetch Pagenames",
            }
        }).then((response) => {
            setPageNameOptions(response?.data?.data?.length > 0 ? uppercaseLabels(response.data.data) : []);

        }).catch((error) => {
            setPageNameOptions([]);
        })

    }, []);

    useEffect(() => {
        getDetailsFromAPI();
    }, [pageNameValue])


    const breadcrumb = [
        { name: "Home", link: "/", isLink: true },
        { name: "Settings", link: "/", isLink: false },
    ];



    return (
        <div className="container-body mb-0">
            <div className="mx-3">
                <Suspense>
                    <Breadcrumbs params={breadcrumb} />
                </Suspense>
            </div>

            {/* EMR TAb for super admin*/}
            <div>
                <Container fluid className="tab-container settings side-tabs">
                    <Row xs={12}>
                        {/* Left Tab */}
                        <Col className="tabs-section1 leftTab" xs={2}>
                            <div className="d-grid gap-2 px-2">
                                {IsAuthorized("Settings", "emr_connectivity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/emr-connectivity");
                                        }}
                                    >
                                        EMR Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/clear-house-connectivity");
                                        }}
                                    >
                                        Clearing House Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "other_settings") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/other-settings");
                                        }}
                                    >
                                        Other Settings
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "credentials") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/integration-credentials");
                                        }}
                                    >
                                        Integration Credentials
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "authorizations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons activeButton"
                                        onClick={() => {
                                            navigate("/settings/authorizations");
                                        }}
                                    >
                                        Authorizations
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "configurations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/configurations");
                                        }}
                                    >
                                        Configurations
                                    </Button>
                                )}

                                {localStorage.getItem("primaryDetailsSelectedTab") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/primary-details");
                                        }}
                                    >
                                        Primary details
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "user_activity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/user-activity");
                                        }}
                                    >
                                        User Activity
                                    </Button>
                                )}

                                {IsAuthorized("DataPlayGround", "page_level") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/data-playground");
                                        }}
                                    >
                                        Data Playground
                                    </Button>
                                )}
                            </div>
                        </Col>

                        <Col className="tabs-section1 rightTab" xs={10}>
                            <>
                                {/* toast message   Type */}
                                {showToaster ? (
                                    <section className="fixed-top" style={{ marginTop: "75px" }}>
                                        <Suspense fallback={<DotsLoader />}>
                                            <ToasterComponet
                                                setShowToaster={setShowToaster}
                                                color={toastColor}
                                                toasterMessage={toasterMessage}
                                            />
                                        </Suspense>
                                    </section>
                                ) : null}

                                <Col md={3}>
                                    <Suspense fallback={<DotsLoader />}>
                                        <SelectDropDown
                                            label={"Page Name"}
                                            dropDownData={pageNameOptions}
                                            defaultVaue={pageNameValue}
                                            setDropDownDataState={setPageNameValue}
                                        // validation={addFormErrors.role}
                                        // name="role" //The name should be same as useState
                                        // formData={addRecordState}
                                        // setFormData={setAddRecordState}
                                        // isMandatory={true}
                                        />
                                    </Suspense>
                                </Col>

                                <section className="py-3">
                                    {isTableLoading ? (
                                        <DotsLoader />
                                    ) : (
                                        <>
                                            {tableData?.length > 0 ? (
                                                <Suspense fallback={<DotsLoader />}>
                                                    <DataTable
                                                        rowData={tableData}
                                                        columnDefs={columnData}
                                                        tableName="Authorizations"
                                                        height="aggrid-vh-70"
                                                        fitColumnsToTable={true}
                                                        clientexport={true}
                                                        clientexportSelectedColumns={true}
                                                        serverExportDynamicColumns={true}
                                                        clientPagination={true}
                                                        buttonData={
                                                            IsAuthorized("Settings", "pd_order_status_crud")
                                                                ? buttonsToShow
                                                                : []
                                                        }
                                                        isAuthorizedtoExport={IsAuthorized(
                                                            "Settings",
                                                            "pd_order_status_export"
                                                        )}
                                                    />
                                                </Suspense>
                                            ) : (
                                                <DataTable
                                                    rowData={[]}
                                                    columnDefs={columnData}
                                                    buttonData={
                                                        IsAuthorized("Settings", "pd_order_status_crud")
                                                            ? buttonsToShow
                                                            : []
                                                    }
                                                    isAuthorizedtoExport={IsAuthorized(
                                                        "Settings",
                                                        "pd_order_status_export"
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </section>

                                {/* Add New Record Modal */}
                                <Modal
                                    show={addRecordModalPopUp}
                                    onHide={hideAddRecordModal}
                                    size="lg"
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                    scrollable
                                    className="pdgm-modal"
                                    contentClassName="rounded-2"
                                >
                                    <Modal.Header
                                        bsPrefix="modal-header app-modal-header px-3"
                                        closeButton={true}
                                    >
                                        <Modal.Title bsPrefix="h6 mb-0" id="volume-tracker">
                                            Add New Record
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body bsPrefix="modal-body p-3">
                                        <Form onSubmit={handleAddNewRecord}>
                                            <Row>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <TextFiledComponent
                                                            labelName={"Page"}
                                                            placeHolder={"enter page name"}
                                                            name="page"
                                                            validation={addFormErrors?.page}
                                                            value={addRecordState.page}
                                                            stateValue={addRecordState}
                                                            setStateValue={setAddRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Role"}
                                                            dropDownData={roleOptions}
                                                            defaultVaue={role}
                                                            setDropDownDataState={setRole}
                                                            validation={addFormErrors.role}
                                                            name="role" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Screen"}
                                                            dropDownData={options}
                                                            defaultVaue={screen}
                                                            setDropDownDataState={setScreen}
                                                            name="screen" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"CTA"}
                                                            dropDownData={options1}
                                                            defaultVaue={cta}
                                                            setDropDownDataState={setCta}
                                                            name="cta" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Export"}
                                                            dropDownData={options1}
                                                            defaultVaue={exportState}
                                                            setDropDownDataState={setExportState}
                                                            name="exportState" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Masking"}
                                                            dropDownData={options1}
                                                            defaultVaue={masking}
                                                            setDropDownDataState={setMasking}
                                                            name="masking" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Comments"}
                                                            dropDownData={options1}
                                                            defaultVaue={comments}
                                                            setDropDownDataState={setComments}
                                                            name="comments" //The name should be same as useState
                                                            formData={addRecordState}
                                                            setFormData={setAddRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Row>
                                                    <Col md={4}>
                                                        <Button
                                                            type="submit"
                                                            className="text-white fs-14 modal-submit-btn fw-bold w-100"
                                                            style={{ height: "48px" }}
                                                        >
                                                            Save Changes
                                                            {submitSpinner ? (
                                                                <Suspense>
                                                                    <LoaderComponent />
                                                                </Suspense>
                                                            ) : null}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>
                                    </Modal.Body>
                                </Modal>

                                {/* Edit Record Modal */}
                                <Modal
                                    show={editRecordModalPopUp}
                                    onHide={hideEditRecordModal}
                                    size="lg"
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                    scrollable
                                    className="pdgm-modal"
                                    contentClassName="rounded-2"
                                >
                                    <Modal.Header
                                        bsPrefix="modal-header app-modal-header px-3"
                                        closeButton={true}
                                    >
                                        <Modal.Title bsPrefix="h6 mb-0" id="volume-tracker">
                                            Edit Record
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body bsPrefix="modal-body p-3">
                                        <Form onSubmit={handleEditNewRecord}>
                                            <Row>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <TextFiledComponent
                                                            labelName={"Page"}
                                                            placeHolder={"enter page name"}
                                                            name="page"
                                                            validation={editFormErrors?.page}
                                                            value={editRecordState.page}
                                                            stateValue={editRecordState}
                                                            setStateValue={setEditRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Role"}
                                                            dropDownData={roleOptions}
                                                            defaultVaue={editRole}
                                                            setDropDownDataState={setEditRole}
                                                            validation={editFormErrors.editRole}
                                                            name="editRole" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Screen"}
                                                            dropDownData={options}
                                                            defaultVaue={editScreen}
                                                            setDropDownDataState={setEditScreen}
                                                            name="editScreen" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                            isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"CTA"}
                                                            dropDownData={options1}
                                                            defaultVaue={editCta}
                                                            setDropDownDataState={setEditCta}
                                                            name="editCta" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Export"}
                                                            dropDownData={options1}
                                                            defaultVaue={editExportState}
                                                            setDropDownDataState={setEditExportState}
                                                            name="editExportState" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Masking"}
                                                            dropDownData={options1}
                                                            defaultVaue={editMasking}
                                                            setDropDownDataState={setEditMasking}
                                                            name="editMasking" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={6}>
                                                    <Suspense fallback={<DotsLoader />}>
                                                        <SelectDropDown
                                                            label={"Comments"}
                                                            dropDownData={options1}
                                                            defaultVaue={editComments}
                                                            setDropDownDataState={setEditComments}
                                                            name="editComments" //The name should be same as useState
                                                            formData={editRecordState}
                                                            setFormData={setEditRecordState}
                                                        // isMandatory={true}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Row>
                                                    <Col md={4}>
                                                        <Button
                                                            type="submit"
                                                            className="text-white fs-14 modal-submit-btn fw-bold w-100"
                                                            style={{ height: "48px" }}
                                                        >
                                                            Save Changes
                                                            {submitSpinner ? (
                                                                <Suspense>
                                                                    <LoaderComponent />
                                                                </Suspense>
                                                            ) : null}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Form>
                                    </Modal.Body>
                                </Modal>

                                {/* Delete Record Modal */}
                                <Modal
                                    show={deleteRecordModalPopUp}
                                    onHide={hideDeleteRecordModal}
                                    size="md"
                                    centered
                                    backdrop="static"
                                    keyboard={false}
                                    scrollable
                                    contentClassName="rounded-4"
                                >
                                    <Modal.Header
                                        bsPrefix="modal-header app-modal-header px-3"
                                        closeButton={true}
                                    >
                                        <Modal.Title bsPrefix="h6 mb-0" id="volume-tracker">
                                            Delete Record
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body bsPrefix="modal-body p-3">
                                        <p className="fs-14">Are you sure you want to delete this record?<br />
                                            Record can't be recovered once deleted.</p>
                                        <Stack
                                            className="justify-content-center"
                                            direction="horizontal"
                                            gap={3}
                                        >
                                            <Button
                                                onClick={handleDeleteNewRecord}
                                                type="submit"
                                                className="btn-danger  text-white fs-14 modal-submit-btn fw-bold px-3 w-50"
                                                style={{ height: "48px" }}
                                            >
                                                Delete Record
                                            </Button>
                                            <Button
                                                type="submit"
                                                onClick={hideDeleteRecordModal}
                                                className="text-primary fs-14 fw-bold px-3 w-50 border border-primary"
                                                variant="outline-light"
                                                style={{ height: "48px" }}
                                            >
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </Modal.Body>
                                </Modal>
                            </>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Authorizations;