import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { IsAuthorized } from '../../../Common/utilsFunctions/IsAuthorized';
import { useNavigate } from 'react-router-dom';
import DotsLoader from '../../../Common/DotsLoader';
import { AxiosInstance } from '../../../Components/Util_Functions/AxiosInstance';
import { integrationCredentialsUrl } from '../../../Utils/Urls';

const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const TextFiledComponent = lazy(() =>
    import("../../../Common/FormTemplates/TextFiledComponent")
);

const Creds = () => {
    const navigate = useNavigate();
    /*BreadCrumb States */
    const breadcrumb = [
        { name: "Home", link: "/", isLink: true },
        { name: "Settings", link: "/", isLink: false },
    ];

    const [credData, setCredData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        AxiosInstance.get(integrationCredentialsUrl, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "Integration Credentials",
                "Screen-Component": "Fetch Credentials",
            }
        })
            .then((response) => {
                setCredData(response?.data?.length > 0 ? response?.data[0] : []);
            }).catch((error) => {
                setCredData("");
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])


    return (
        <div className="container-body mb-0">
            <div className="mx-3">
                <Suspense>
                    <Breadcrumbs params={breadcrumb} />
                </Suspense>
            </div>

            {/* EMR TAb for super admin*/}
            <div>
                <Container fluid className="tab-container settings side-tabs">
                    <Row xs={12}>
                        {/* Left Tab */}
                        <Col className="tabs-section1 leftTab" xs={2}>
                            <div className="d-grid gap-2 px-2">
                                {IsAuthorized("Settings", "emr_connectivity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/emr-connectivity");
                                        }}
                                    >
                                        EMR Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/clear-house-connectivity");
                                        }}
                                    >
                                        Clearing House Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "other_settings") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/other-settings");
                                        }}
                                    >
                                        Other Settings
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "credentials") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons activeButton"
                                    >
                                        Integration Credentials
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "authorizations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/authorizations");
                                        }}
                                    >
                                        Authorizations
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "configurations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/configurations");
                                        }}
                                    >
                                        Configurations
                                    </Button>
                                )}

                                {localStorage.getItem("primaryDetailsSelectedTab") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/primary-details");
                                        }}
                                    >
                                        Primary details
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "user_activity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/user-activity");
                                        }}
                                    >
                                        User Activity
                                    </Button>
                                )}

                                {IsAuthorized("DataPlayGround", "page_level") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/data-playground");
                                        }}
                                    >
                                        Data Playground
                                    </Button>
                                )}
                            </div>
                        </Col>

                        <Col className="tabs-section1 rightTab" xs={10}>
                            {loading ? <DotsLoader height='h-100' /> :
                                credData ?
                                    <div className="ps-3 pe-5 me-3 mb-4">
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Production Postgres Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Host"}
                                                            isReadOnly={true}
                                                            value={credData?.production_postgres_credentials?.host}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User"}
                                                            isReadOnly={true}
                                                            value={credData?.production_postgres_credentials?.user}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.production_postgres_credentials?.password}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Database"}
                                                            isReadOnly={true}
                                                            value={credData?.production_postgres_credentials?.database}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Development Postgres Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Host"}
                                                            isReadOnly={true}
                                                            value={credData?.development_postgres_credentials?.host}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User"}
                                                            isReadOnly={true}
                                                            value={credData?.development_postgres_credentials?.user}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.development_postgres_credentials?.password}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Database"}
                                                            isReadOnly={true}
                                                            value={credData?.development_postgres_credentials?.database}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Staging Postgres Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Host"}
                                                            isReadOnly={true}
                                                            value={credData?.staging_postgres_credentials?.host}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User"}
                                                            isReadOnly={true}
                                                            value={credData?.staging_postgres_credentials?.user}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.staging_postgres_credentials?.password}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Database"}
                                                            isReadOnly={true}
                                                            value={credData?.staging_postgres_credentials?.database}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">AWS Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"AWS Access Key ID"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.aws_access_key_id}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"AWS Secret Access Key"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.aws_secret_access_key}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"AWS Region Name"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.aws_region_name}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"S3 Bucket Name"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.s3_bucket_name}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"S3 Path Eligibility"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.s3_path_eligibility}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"S3 Waystar Copies"}
                                                            isReadOnly={true}
                                                            value={credData?.aws_credentials?.s3_waystar_copies}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Snowflake Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Account"}
                                                            isReadOnly={true}
                                                            value={credData?.snowflake_credentials?.account}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User"}
                                                            isReadOnly={true}
                                                            value={credData?.snowflake_credentials?.user}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.snowflake_credentials?.password}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Eligibility API Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User ID"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.userid}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.password}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Provider Name"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.provider_name}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Provider NPI"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.provider_npi}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Data Format"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.dataFormat}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Response Type"}
                                                            isReadOnly={true}
                                                            value={credData?.eligibility_api_credentials?.responsetype}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="section-header-Emr">Django Superadmin Credentials</div>
                                                <div className="section-header-line flex-grow-1 ms-5">
                                                    <hr />
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"User Name"}
                                                            isReadOnly={true}
                                                            value={credData?.django_superadmin_credentials?.username}
                                                        />
                                                    </Suspense>
                                                </Col>

                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Email"}
                                                            isReadOnly={true}
                                                            value={credData?.django_superadmin_credentials?.email}
                                                        />
                                                    </Suspense>
                                                </Col>
                                                <Col md={4}>
                                                    <Suspense fallback={<DotsLoader height={"h-100"} />}>
                                                        <TextFiledComponent
                                                            labelName={"Password"}
                                                            isReadOnly={true}
                                                            value={credData?.django_superadmin_credentials?.pwd}
                                                        />
                                                    </Suspense>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div> :
                                    <div className='d-flex justify-content-center align-items-center h-100 fs-18'>You are unauthorized to view these details</div>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Creds;