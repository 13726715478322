import axios from "axios";
import { hostUrl, refreshTokenUrl } from "../../Utils/Urls";
import getNewAccessToken from "../../Common/GlobalRefreshToken/RefreshToken";

let key = JSON.parse(localStorage.getItem("hnts_access_token"));
const user = JSON.parse(localStorage.getItem("hnts_user"));
const IpAddress = localStorage.getItem("IPAddress");

// Create an instance of axios
const AxiosInstance = axios.create({
  baseURL: hostUrl,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${key}`,
    Accept: "application/json",
    'Client-IP': IpAddress
  },
});

// Create a separate instance for refreshing token
const axiosRefreshInstance = axios.create({
  baseURL: hostUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Add a request interceptor to modify the payload
AxiosInstance.interceptors.request.use(
  (config) => {
    // Merge custom headers with default headers if provided
    if (config.customHeaders) {
      config.headers = {
        ...AxiosInstance.defaults.headers,
        ...config.headers,
        ...config.customHeaders,
      };
      delete config.customHeaders; // Clean up customHeaders to avoid redundancy
    }
    // Check if the data is FormData and skip modification if it is
    if (config.data instanceof FormData) {
      return config;
    }
    // Ensure the data payload is correctly formatted
    if (config.data && typeof config.data === "string") {
      try {
        config.data = JSON.parse(config.data);
      } catch (e) {
        // console.error("Failed to parse config.data:", config.data);
      }
    }

    // Add the consolidated_role_and_dep key-value pair to the payload
    config.data = {
      ...config.data,
      consolidated_role_and_dep: user?.consolidated_role_and_dep,
    };

    return config;
  },
  (error) => {
    // Handle the request error here
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (originalRequest.url.includes(refreshTokenUrl)) {
        // If the refresh token request fails, log out the user or take necessary actions
        // console.log("logout the user");
        window.location.href = "/logout";
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return AxiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        getNewAccessToken()
          .then((token) => {
            AxiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${token}`;
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            processQueue(null, token);
            resolve(AxiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            // If refresh token request fails, log out the user
            window.location.href = "/logout";
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

export { AxiosInstance, axiosRefreshInstance };
