


// function to uppercase labels in an array of objects
function uppercaseLabels(data) {
    return data.map(item => ({
        label: item.label.toUpperCase(),
        value: item.value
    }));
}

// let formatter = new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: "USD",
// });

export default uppercaseLabels;