import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import eyeInActive from '../../../assets/images/eye-inactive.png';
import eyeActive from '../../../assets/images/eye-active.png';
import { PasswordResetURL } from "../../../Utils/Urls";
import { Row, Spinner, Toast } from 'react-bootstrap';

const ResetPassword = () => {

    let navigate = useNavigate();
    let params = useParams();


    const AxiosInstance = axios.create();

    const initialValues = {
        password: "",
        confpassword: "",
    };

    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState("");
    const [spinner, setSpinner] = useState(false);

    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const [stateconf, setstateconf] = useState(true)
    const [statecreate, setstatecreate] = useState(true)

    // toggle password show/hide icon
    const togglePassword = () => {
        setstatecreate(!statecreate)
    }

    // toggle password show/hide icon
    const toggleconfPassword = () => setstateconf(!stateconf)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }
    // handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setSpinner(true);
            var dataTobeSend = {
                new_password1: formValues.password, new_password2: formValues.confpassword,
                uid: params.uuid, token: params.token
            };
            let requiredUrl = `${PasswordResetURL}/${params.uuid}/${params.token}/`
            AxiosInstance.post(requiredUrl, dataTobeSend).then((response) => {
                setFormValues({
                    password: "",
                    confpassword: "",
                });
                setIsSubmit(false);
                setShowToast(true);
                setToastMsg(response.data.detail);
                setToastType("success");
                setTimeout(() => {
                    navigate("/login")
                }, 3500);
                setSpinner(false);
            }).catch(error => {
                setShowToast(true);
                setToastMsg(error.response.data.new_password2 ? error.response.data.new_password2[0] : "You already used the above link for resetting password");
                setToastType("error");
                setFormValues({
                    password: "",
                    confpassword: "",
                });
                setIsSubmit(false);
                setSpinner(false);
                // console.log("error response", error.response.data.new_password2[0]);
            })
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (values.password && !values.confpassword) {
            errors.confpassword = "Confirm Passsword is required!";
        }
        if (!values.password) {
            errors.password = "Passsword is required!";
        }
        if (values.password.length > 0 && values.confpassword.length > 0 && values.password !== values.confpassword) {
            errors.confpassword = "Passwords doesn't match!";
        }
        return errors;
    };



    return (
        <div className="form-section loginheight">
            {showToast ? (
                <Row className="toast-row toast-abs">
                    <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        className={toastType}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>{toastMsg}</Toast.Body>
                    </Toast>
                </Row>
            ) : (
                ""
            )}
            <div className="form-header">Set new password</div>
            <div className='form-msg'>
                {formErrors.password && (
                    <div className="login-text-error">{formErrors.password}</div>
                )}
                {formErrors.confpassword && (
                    <div className="login-text-error">{formErrors.confpassword}</div>
                )}
            </div>
            <form onSubmit={handleSubmit} >
                <div className="form-field reset-field">
                    <label>
                        Create Password
                    </label>
                    <input
                        type={statecreate ? "password" : "text"}
                        name="password"
                        id="password"
                        className='form-control'
                        placeholder='password'
                        onChange={handleChange}
                        value={formValues.password}
                    />
                    <div className="footer-text">Your password should be of minimum 8 characters</div>
                    {formValues.password.length > 0 && (
                        <div className="eye-icon reset-eye-icon2" onClick={togglePassword}>
                            {statecreate === true && (
                                <img src={eyeInActive} alt="Hide Password" />
                            )}
                            {statecreate === false && (
                                <img src={eyeActive} alt="Show Password" />
                            )}
                        </div>
                    )}
                </div>

                <div className="form-field reset-field2">
                    <label>
                        Confirm Password
                    </label>
                    <input
                        type={stateconf ? "password" : "text"}
                        name="confpassword"
                        id="confpassword"
                        className='form-control'
                        placeholder='password'
                        onChange={handleChange}
                        value={formValues.confpassword}
                    />
                    <div className="footer-text">Please retype the same password you've entered before</div>
                    {formValues.confpassword.length > 0 && (
                        <div className="eye-icon reset-eye-icon2" onClick={toggleconfPassword}>
                            {stateconf === true && (
                                <img src={eyeInActive} alt="Hide Password" />
                            )}
                            {stateconf === false && (
                                <img src={eyeActive} alt="Show Password" />
                            )}
                        </div>
                    )}

                </div>

                {/* <div className="mt-4" /> */}

                <div className="form-footer-msg"></div>
                <Link className='text-center d-block mb-2' to="/login">Back to login</Link>
                <button className="submit-btn">
                    <div className="submit-text">Submit
                        {spinner && (
                            <span
                                style={{
                                    paddingLeft: "10px",
                                    boxSizing: "border-box",
                                }}
                            >
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                ></Spinner>
                            </span>
                        )}</div>
                </button>
            </form>
        </div>
    );
}

export default ResetPassword


