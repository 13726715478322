import React from "react";
import logo from "../assets/images/logo.png";
import VigeoDashLogo from "../assets/images/VigeoDashLogo.svg";
import avatar from "../assets/images/avatar.png";
import arrowindication from "../assets/images/arrow-indication.svg";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/css/styles.css";
import "../assets/css/header.css";
import { IsAuthorized } from "../Common/utilsFunctions/IsAuthorized";
let stopViewingHeaders = window.location.href.includes("hnts");
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserDetails: JSON.parse(localStorage.getItem("hnts_user")),
      clinicalTitle: "Clinical",
      businessDevelopementTitle: "Business Development",
      intakeTitle: "Intake",
      authorizationsTitle: "Authorizations/QA",
      billingTitle: "Billing",
      reprotsTitle: "Reports",
      Experiment: "Experimental",
    };
    this.businessDevelopementSelect =
      this.handlebusinessDevelopementSelect.bind(this);
  }
  handlebusinessDevelopementSelect(e) {
    //this.setState({ businessDevelopementTitle: e })
  }

  render() {
    return (
      <div className="header d-flex justify-content-between">
        <div className="left flex-wrap flex-fill d-flex justify-content-start align-items-center gap-3">
          <div className="header-logo pe-3">
            <Link to="/">
              <img
                src={VigeoDashLogo}
                className="logo"
                alt="VigeoDashLogo"
                id="logoVigeo"
              />
            </Link>
          </div>
          <div className="business-developement">
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-business-developement"
              >
                {this.state.intakeTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  <Dropdown.Item as={Link} to="/intake">
                    Admission
                  </Dropdown.Item>
                  {IsAuthorized("PatientEligibility", "page_level") && (
                    <Dropdown.Item as={Link} to="/patient-eligibility">
                      Patient Eligibility
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="business-developement">
            <Dropdown onSelect={this.businessDevelopementSelect}>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-business-developement"
              >
                {this.state.businessDevelopementTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {IsAuthorized("Referrals", "page_level") && (
                    <Dropdown.Item as={Link} to="/referrals">
                      Referrals
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="clinical">
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-clinical"
              >
                {this.state.clinicalTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {IsAuthorized("Productivity", "page_level") && (
                    <Dropdown.Item as={Link} to="/productivity">
                      Productivity
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("Census", "page_level") && (
                    <Dropdown.Item as={Link} to="/census">
                      Census
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("PCCDashboard", "page_level") && (
                    <Dropdown.Item as={Link} to="/pcc-dashboard">
                      PCC Dashboard
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("PastDueVisits", "page_level") && (
                    <Dropdown.Item as={Link} to="/past-due-visits">
                      Past Due Visits
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("PDGMManagement", "page_level") && (
                    <Dropdown.Item as={Link} to="/pdgm-management">
                      PDGM Management
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("PerVisitManagement", "page_level") && (
                    <Dropdown.Item as={Link} to="/per-visit-management">
                      Per Visit Management
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("ScheduleDeviation", "page_level") && (
                    <Dropdown.Item as={Link} to="/schedule-deviation">
                      Schedule Deviation
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("DischargePlanning", "page_level") && (
                    <Dropdown.Item as={Link} to="/discharge-planning">
                      Discharge Planning
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("LupaRisk", "page_level") && (
                    <Dropdown.Item as={Link} to="/lupa-risk">
                      LUPA Risk
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("BlankHipps", "page_level") && (
                    <Dropdown.Item as={Link} to="/blank-hipps">
                      Blank HIPPS
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("IncorrectMRNs", "page_level") && (
                    <Dropdown.Item as={Link} to="/incorrect-mrns">
                      Incorrect MRNs
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="admin">
            <Dropdown onSelect={this.adminSelect}>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-clinical"
              >
                {this.state.authorizationsTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {/* <Dropdown.Item enentkey="Billing" as={Link} to="#">Billing</Dropdown.Item> */}
                  <Dropdown.Item
                    enentkey="Billing"
                    as={Link}
                    to="/authorization-summary"
                  >
                    Authorization Summary
                  </Dropdown.Item>

                  {IsAuthorized("MissingAuthorizations", "page_level") && (
                    <Dropdown.Item as={Link} to="/authorization">
                      Missing Authorizations
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("ReviewChoiceDemonstration", "page_level") && (
                    <Dropdown.Item as={Link} to="/review-choice-demonstration">
                      RCD
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("Orders", "page_level") && (
                    <Dropdown.Item as={Link} to="/orders">
                      Orders
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="admin">
            <Dropdown onSelect={this.adminSelect}>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-clinical"
              >
                {this.state.billingTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {/* <Dropdown.Item enentkey="Billing" as={Link} to="#">Billing</Dropdown.Item> */}

                  {IsAuthorized("BillingScreen", "page_level") && (
                    <Dropdown.Item
                      enentkey="Billing"
                      as={Link}
                      to="/billing-dashboard"
                    >
                      Billing Summary
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("DenialClaims", "page_level") && (
                    <Dropdown.Item as={Link} to="/denied-claims">
                      Denied Claims
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("PatientResponsibility", "page_level") && (
                    <Dropdown.Item as={Link} to="/patient-responsibility">
                      Patient Responsibility
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("AgedAr", "page_level") && (
                    <Dropdown.Item as={Link} to="/billing/aged-ar">
                      AgedAR
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("BillingRemittances", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      to="/billing/billing-and-remittances/remittances-Insurance"
                    >
                      Remittances
                    </Dropdown.Item>
                  )}

                  {IsAuthorized("BillingBatchReport", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      to="/billing/billing-and-remittances/billing-batch-report"
                    >
                      Billing Batch
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="experimental">
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-clinical"
              >
                {this.state.reprotsTitle}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    style={{ padding: "5px" }}
                    src={arrowindication}
                    alt="arrow"
                  />
                  {/* <Dropdown.Item> */}
                  <Dropdown className="fixed-drop-down mx-2 mb-2 nested-dropdown">
                    <Dropdown.Toggle
                      size="sm"
                      variant="secondary"
                      // id="dropdown-clinical"
                      className="w-100 px-3 text-start position-relative"
                    >
                      Fixed
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="drop-rel">
                        <img
                          className="UpperArrow"
                          src={arrowindication}
                          alt="arrow"
                        />

                        {IsAuthorized(
                          "EpisodeVisitAuthSummary",
                          "page_level"
                        ) && (
                            <Dropdown.Item
                              as={Link}
                              to="/episode-visit-auth-summary"
                            >
                              Episode visit Auth Summary
                            </Dropdown.Item>
                          )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* 
                  <Dropdown.Item as={Link} to="/ad-hoc">
                    Ad-hoc
                  </Dropdown.Item> */}

                  <Dropdown className="fixed-drop-down mx-2 nested-dropdown">
                    <Dropdown.Toggle
                      size="sm"
                      variant="secondary"
                      // id="dropdown-clinical"
                      className="w-100 px-3 text-start"
                    >
                      Ad-hoc
                    </Dropdown.Toggle>
                    {IsAuthorized("Users", "page_level") && (
                      <Dropdown.Menu>
                        <div className="drop-rel">
                          <img
                            className="UpperArrow"
                            src={arrowindication}
                            alt="arrow"
                          />

                          {IsAuthorized("DataPlayGround", "page_level") && (
                            <Dropdown.Item
                              as={Link}
                              to="/settings/data-playground"
                            >
                              Data Playground
                            </Dropdown.Item>
                          )}
                        </div>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>

                  {IsAuthorized("ProviderProfitability", "page_level") && (
                    <Dropdown.Item as={Link} to="/provider_profitability">
                      Provider Profitability
                    </Dropdown.Item>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="experimental">
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-clinical"
              >
                {this.state.Experiment}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrow"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {IsAuthorized("AutoPosting", "page_level") &&
                    !stopViewingHeaders ? (
                    <Dropdown.Item as={Link} to="/autoposting">
                      Auto Posting
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  {IsAuthorized("DenialClaims", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      to="/work-in-progress/denied-claims"
                    >
                      Denied Claims
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("BillingScreen", "page_level") && (
                    <Dropdown.Item as={Link} to="/edi-billing-dashboard">
                      EDI Billing Summary
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("AgedAr", "page_level") && (
                    <Dropdown.Item as={Link} to="/experimental/aged-ar">
                      EDI AgedAR
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("DenialClaims", "page_level") && (
                    <Dropdown.Item as={Link} to="/edi-denied-claims">
                      EDI Denied Claims
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("ViewTreeStructure", "page_level") &&
                    !stopViewingHeaders ? (
                    <Dropdown.Item as={Link} to="/view-tree-structure">
                      Object Tree Structure
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  {/* {IsAuthorized("DenialClaims", "page_level") && ( */}
                  {!stopViewingHeaders ? <Dropdown.Item as={Link} to="/pivot-table">
                    Pivot Table
                  </Dropdown.Item> : ""}
                  {/* )} */}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="right d-flex justify-content-end align-items-center gap-2">
          <div className="healthcare-logo">
            <img src={logo} className="logo" alt="logoNotFound" />
          </div>
          <div className="profile-logo">
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="secondary"
                id="dropdown-account"
              >
                <img src={avatar} className="avatar" alt="Avatar" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="drop-rel">
                  <img
                    className="UpperArrowright"
                    src={arrowindication}
                    alt="arrow"
                  />
                  {IsAuthorized("Users", "page_level") && (
                    <Dropdown.Item as={Link} to="/users">
                      Users
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("Settings", "page_level") && (
                    <Dropdown.Item as={Link} to="/settings">
                      Settings
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("SyncHistory", "page_level") && (
                    <Dropdown.Item as={Link} to="/sync-history">
                      Sync History
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("ReleaseHistory", "page_level") && (
                    <Dropdown.Item as={Link} to="/release-history">
                      Release History
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("Help", "page_level") && (
                    <Dropdown.Item as={Link} to="/help">
                      Help
                    </Dropdown.Item>
                  )}
                  {IsAuthorized("ReportBug", "page_level") && (
                    <Dropdown.Item
                      as={Link}
                      target="_blank"
                      to="https://forms.clickup.com/9006116846/f/8ccwvze-2873/2ZC2PHU67WF1RMER6N"
                    >
                      Report An Issue
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    as={Link}
                    to="/logout"
                    state={{ list: "manuallogout" }}
                  >
                    Logout
                  </Dropdown.Item>
                  {/* <Dropdown.Item as={Link} to="/new-page">New Page</Dropdown.Item> */}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
