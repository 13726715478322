function Footer() {
    return (
        <div style={{ background: "#F8F8F8", width: "100%", position: "fixed", bottom: "0", zIndex: "1005" }}>
            <div style={{ float: "right", margin: "0px 20px 0px 0px", fontSize: "0.7rem" }}>
                Powered by&nbsp;
                <a href="https://healthcareintegrative.com/" target={"_blank"} style={{ textDecoration: "none" }} rel="noreferrer">
                    Healthcare Integrative Solutions
                </a>
                .&nbsp;All Rights reserved.
            </div>
        </div>
    )
}
export default Footer