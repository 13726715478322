import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionButton, Button, Col, Container, Dropdown, Form, Modal, Overlay, OverlayTrigger, Pagination, Popover, Row, Tooltip } from 'react-bootstrap';
import { IsAuthorized } from '../../../Common/utilsFunctions/IsAuthorized';
import { useNavigate } from 'react-router-dom';
import { handleNullValues } from '../../../Common/utilsFunctions/handleNullValues';
import { AxiosInstance } from '../../../Components/Util_Functions/AxiosInstance';
import { userActivityUrl, userIDsUrl } from '../../../Utils/Urls';
import { convertToSettingsTimeFormat } from '../../../Common/utilsFunctions/convertingToSettingTimeFormat';
import DotsLoader from "../../../Common/DotsLoader";
import { PascalCaseString } from '../../../Common/utilsFunctions/PascalCase_UserRole';
import filterIcon from "../../../assets/images/filter.svg"
import { apiDateFormat } from '../../../Common/utilsFunctions/apiDateFormat';
import LoaderComponent from '../../../Common/LoaderComponent';
import ExportIcon from "../../../assets/images/export.png";
import SortUp from "../../../assets/images/sortUp.svg";
import SortDown from "../../../assets/images/sortDown.svg";
import SelectDropDown from "../../../Common/SelectDropDown";

const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const ServerSideExportMultiSection = lazy(() =>
    import(
        "./ServerSideExportMultiSection/ServerSideExportMultiSection"
    )
);
const UserActivity = () => {
    const navigate = useNavigate();
    const initialRender = useRef(true);
    /*BreadCrumb States */
    const breadcrumb = [
        { name: "Home", link: "/", isLink: true },
        { name: "Settings", link: "/", isLink: false },
    ];
    //Accordian related states
    const [initialDataLoading, setInitialDataLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [accordionBodyLoading, setAccordionBodyLoading] = useState(false);
    const [accordionBodyData, setAccordionBodyData] = useState([]);
    const [viewMoreClickCount, setViewMoreClickCount] = useState(0);
    const [viewMoreLoader, setViewMoreLoader] = useState(false);
    const [isViewMoreClickable, setIsViewMoreClickable] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [activePage, setActivePage] = useState(1);
    const paginationItems = [];
    const activePageUpdate = useRef(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [viewActivity, SetViewActivity] = useState("yes");
    const [previousPayloadOfAccordionBody, setPreviousPayloadOfAccordionBody] =
        useState("");
    const [previousActivity, setPreviousActivity] =
        useState("");

    /*Filter Related Dropdowns */
    const targetfilter = useRef(null);
    const [showfilter, setShowfilter] = useState(false);
    const [targetfilterstate, setTargetfilterstate] = useState(null);
    const [userIDOptions, setUserIDOptions] = useState([]);
    const [selectedUSerId, setSelectedUSerId] = useState(
        { label: "ALL", value: "ALL" }
    );

    //Last week
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7);

    //Last month
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30);


    //Last 3 month
    const last90Days = new Date();
    last90Days.setDate(last90Days.getDate() - 90);

    //Last 6 month
    const last180Days = new Date();
    last180Days.setDate(last180Days.getDate() - 180);

    //Last 6 month
    const last365Days = new Date();
    last365Days.setDate(last365Days.getDate() - 365);


    const [datePeriodOptions, setDatePeriodOptions] = useState([
        { label: "CURRENT DAY", value: [apiDateFormat(new Date()), apiDateFormat(new Date())] },
        { label: "LAST WEEK", value: [apiDateFormat(last7Days), apiDateFormat(new Date())] },
        { label: "LAST MONTH", value: [apiDateFormat(last30Days), apiDateFormat(new Date())] },
        { label: "LAST 3 MONTHS", value: [apiDateFormat(last90Days), apiDateFormat(new Date())] },
        { label: "LAST 6 MONTHS", value: [apiDateFormat(last180Days), apiDateFormat(new Date())] },
        { label: "LAST YEAR", value: [apiDateFormat(last365Days), apiDateFormat(new Date())] },
    ]);
    const [selectedDatePeriod, setSelectedDatePeriod] = useState(
        {
            label: "CURRENT DAY",
            value: [apiDateFormat(new Date()), apiDateFormat(new Date())]
        });
    const [filterLoader, setFilterLoader] = useState(false);

    // serverside dynamic export columnsonBtnExport states
    const [exportPopUpModal, setexportPopUpModal] = useState(false);
    let exportColumns = {
        session_fields: {
            user: "activity_user",
            session_started_at: "session_start_time_string",
            session_ended_at: "session_end_time_string",
            session_duration: "duration",
            ip_address: "user_ip",
            agent_id: "user_agent",
            session_id: "session_id"
        },
        session_activity_fields: {
            screen: "screen",
            section: "section",
            element: "component",
            action_performed_at: "action_performed_at"
        }
    };
    const [serverSideExportPayload, setServerSideExportPayload] = useState({});

    /*Sorting related states */
    const [sortOrder, setSortOrder] = useState("yes"); // yes is ascending order
    const [accordionBodySortOrder, setAccordionBodySortOrder] = useState("yes"); // yes is ascending order

    /*This function will be triggered when we click on filter icon */
    const handleClick = (event) => {
        setShowfilter(!showfilter);
        setTargetfilterstate(event.target);
    };

    /*Tool tip for export */
    const renderExport = (props) => (
        <Tooltip style={{ background: "red" }} id="button-tooltip" {...props}>
            Export Data
        </Tooltip>
    );

    const handleExportPopUp = () => {
        setexportPopUpModal(true);
    };

    const CloseExportModal = () => {
        setexportPopUpModal(false);
    };


    /*This function will be triggered when accordion body is clicked */
    const accordionClick = (clickedValue, rowData) => {
        setViewMoreClickCount(0);
        if (previousPayloadOfAccordionBody !== rowData || previousActivity !== clickedValue) {
            setPreviousActivity(clickedValue);
            setPreviousPayloadOfAccordionBody(rowData);
            setAccordionBodyLoading(true);
            let { id, session_id } = rowData;
            let dataToBeSend = {
                "excel_export": "false",
                "offset": 0,
                "limit": 5,
                "screen_type": clickedValue === "yes" ? "view_activity" : "view_activity_per_screen",
                "sort_type": accordionBodySortOrder,
                "filters": [
                    {
                        "colname": "user_id",
                        "isin": [id]
                    }
                    ,
                    {
                        "colname": "session_id",
                        "isin": [session_id],
                    }
                ]
            }
            AxiosInstance.post(userActivityUrl, dataToBeSend, {
                customHeaders: {
                    'Screen-Name': 'Settings',
                    "Screen-Section": "User Activity",
                    "Screen-Component": "Accordion",
                }
            }).then((response) => {
                let APIResponse = response?.data
                setAccordionBodyData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
                //View more click control
                let clickControl = APIResponse?.Total_rows > 0 ? Math.ceil(APIResponse?.Total_rows / 5) - 1 : 0;
                if (clickControl === (viewMoreClickCount > 0 ? viewMoreClickCount - 1 : 0)) {
                    setIsViewMoreClickable(false);
                } else {
                    setIsViewMoreClickable(true);
                }
            }).catch(() => {
                setAccordionBodyData([]);
            }).finally(() => {
                setAccordionBodyLoading(false);
            });
        } else {
            setPreviousActivity("");
            setPreviousPayloadOfAccordionBody("");
        }
    };

    /*This function will be triggered when accordion body value changes */
    const handleAccordionBodySorting = (clickedValue, rowData, sortvalue) => {
        setViewMoreClickCount(0);
        setAccordionBodyLoading(true);
        let { id, session_id } = rowData;
        let dataToBeSend = {
            "excel_export": "false",
            "offset": 0,
            "limit": 5,
            "screen_type": clickedValue === "yes" ? "view_activity" : "view_activity_per_screen",
            sort_type: sortvalue,
            "filters": [
                {
                    "colname": "user_id",
                    "isin": [id]
                }
                ,
                {
                    "colname": "session_id",
                    "isin": [session_id],
                }
            ]
        }
        AxiosInstance.post(userActivityUrl, dataToBeSend, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "User Activity",
                "Screen-Component": "Accordion Cards Sorting",
            }
        }).then((response) => {
            let APIResponse = response?.data
            setAccordionBodyData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
            //View more click control
            let clickControl = APIResponse?.Total_rows > 0 ? Math.ceil(APIResponse?.Total_rows / 5) - 1 : 0;
            // if (clickControl === viewMoreClickCount) {
            //     setIsViewMoreClickable(false);
            // } else {
            setIsViewMoreClickable(true);
            // }
        }).catch(() => {
            setAccordionBodyData([]);
        }).finally(() => {
            setAccordionBodyLoading(false);
        });
    }

    const handleViewMore = (e, clickedValue, rowData) => {
        setViewMoreLoader(true);
        e.preventDefault();
        setViewMoreClickCount(viewMoreClickCount + 1);
        let { id, session_id } = rowData;
        let dataToBeSend = {
            "excel_export": "false",
            "offset": (viewMoreClickCount + 1) * 5,
            "limit": 5,
            "screen_type": clickedValue === "yes" ? "view_activity" : "view_activity_per_screen",
            "sort_type": accordionBodySortOrder,
            "filters": [
                {
                    "colname": "user_id",
                    "isin": [id]
                }
                ,
                {
                    "colname": "session_id",
                    "isin": [session_id],
                }
            ]

        }
        AxiosInstance.post(userActivityUrl, dataToBeSend).then((response) => {
            let APIResponse = response?.data
            let APIResponseData = APIResponse?.table_data
            setAccordionBodyData([...accordionBodyData, ...APIResponseData]);
            /**The below is to check view more click control when 
            the Total no of rows is grater than 1 when it is divided 
            by no.of cards showing by default in body.Thats the reason 
            we are dividing it by total number of pages with total no of cards showing
            and making it -1 .Then we'll know how many clicks can be done**/
            let clickControl = Math.ceil(APIResponse?.Total_rows / 5) - 1;
            //As we state will be updated later we are doing plus 1
            if (clickControl === viewMoreClickCount + 1) {
                setIsViewMoreClickable(false);
            } else {
                setIsViewMoreClickable(true);
            }
        }).catch(() => {
            // setAccordionBodyData([]);
        }).finally(() => {
            // setAccordionBodyLoading(false);
            setViewMoreLoader(false);
        });
    }

    /*This function will be triggretd when view all visits is changed */
    const handleViewAllChange = (clickedValue, rowData) => {
        setViewMoreClickCount(0);
        SetViewActivity(clickedValue);
        accordionClick(clickedValue, rowData);
    };
    /* This function shows first page details */
    const handlePageFirst = () => {
        setActivePage(1);
        // setresetUpdate(!resetUpdate);
        activePageUpdate.current = true;
    };

    /* This function shows last page details */
    const handlePageLast = () => {
        setActivePage(totalPages);
        // setresetUpdate(!resetUpdate);
        activePageUpdate.current = true;
    };

    /* This function shows details of previous page from active page */
    const handlePagePrevious = (e) => {
        setActivePage(Number(activePage) - 1);
        // setresetUpdate(!resetUpdate);
        activePageUpdate.current = true;
    };

    /* This function shows details of next page from active page */
    const handlePageNext = (e) => {
        setActivePage(Number(activePage) + 1);
        // setresetUpdate(!resetUpdate);
        activePageUpdate.current = true;
    };

    /* This function handles current item click */
    const handlePageItemClick = (page) => {
        setActivePage(page);
        activePageUpdate.current = true;
    };

    /* This function handles page size limit to show rows base on dropdown selection */
    const handlePageSize = (e) => {
        setPageSize(e);
        setActivePage(1);
        activePageUpdate.current = true;
    };

    /*Creates pagination Item  */
    const createPaginationItem = (i) => {
        return (
            <Pagination.Item
                key={i}
                active={i === activePage}
                onClick={() => handlePageItemClick(i)}
            >
                {i}
            </Pagination.Item>
        );
    };

    if (totalPages > 10) {
        // Add the first item (page 1)
        paginationItems.push(createPaginationItem(1));
        // Add an ellipsis
        paginationItems.push(<Pagination.Ellipsis />);
        const midpoint = Math.ceil(totalPages / 2);
        // Create page numbers in the middle
        for (let i = midpoint; i <= midpoint + 4; i++) {
            paginationItems.push(createPaginationItem(i));
        }
        // Add an ellipsis
        paginationItems.push(<Pagination.Ellipsis />);
        // Add the last item (page N)
        paginationItems.push(createPaginationItem(totalPages));
    } else {
        for (var j = 1; j <= totalPages; j++) {
            paginationItems.push(createPaginationItem(j));
        }
    }

    /*This function will be triggered when we click on apply filters */
    const handleApplyFilters = (e) => {
        e.preventDefault();
        setFilterLoader(true);
        let filters = [];
        if (selectedUSerId?.value === "ALL") {
            filters = [
                {
                    "colname": "session_start_date",
                    "isbetween": selectedDatePeriod?.value
                }]
        } else {
            filters = [{
                "colname": "id",
                "isin": [
                    selectedUSerId?.value
                ]
            },
            {
                "colname": "session_start_date",
                "isbetween": selectedDatePeriod?.value

            }
            ]
        };
        let dataToBeSend = {
            "offset": (activePage - 1) * parseInt(pageSize),
            "limit": parseInt(pageSize),
            "screen_type": "user_sessions",
            "excel_export": "false",
            "filters": filters,
            sort_type: sortOrder
        }
        setServerSideExportPayload({ ...dataToBeSend, excel_export: "true" });
        AxiosInstance.post(userActivityUrl, dataToBeSend, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "User Activity",
                "Screen-Component": "Filters",
            }
        }).then((response) => {
            let APIResponse = response?.data
            setRowData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
            setTotalRecords(APIResponse?.Total_rows || 0);
            setTotalPages(Math.ceil((APIResponse?.Total_rows
                ? APIResponse.Total_rows
                : 0) / pageSize))
        }).catch((error) => {
            setRowData([]);
        }).finally(() => {
            setInitialDataLoading(false);
            setFilterLoader(false);
            setShowfilter(!showfilter);
        })
    }

    //Initial Render
    useEffect(() => {
        let filters = [];
        if (selectedUSerId?.value === "ALL") {
            filters = [
                {
                    "colname": "session_start_date",
                    "isbetween": selectedDatePeriod?.value
                }]
        } else {
            filters = [{
                "colname": "id",
                "isin": [
                    selectedUSerId?.value
                ]
            },
            {
                "colname": "session_start_date",
                "isbetween": selectedDatePeriod?.value
            }
            ]
        };
        let dataToBeSend = {
            "offset": (activePage - 1) * parseInt(pageSize),
            "limit": parseInt(pageSize),
            "screen_type": "user_sessions",
            "excel_export": "false",
            "filters": filters,
            sort_type: sortOrder
        };
        setServerSideExportPayload({ ...dataToBeSend, excel_export: "true" });
        AxiosInstance.post(userActivityUrl, dataToBeSend, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "User Activity",
                "Screen-Component": "",
            }
        }).then((response) => {
            let APIResponse = response?.data
            setRowData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
            setTotalRecords(APIResponse?.Total_rows || 0);
            setTotalPages(Math.ceil((APIResponse?.Total_rows
                ? APIResponse.Total_rows
                : 0) / pageSize));
        }).catch((error) => {
            setRowData([]);
        }).finally(() => {
            setInitialDataLoading(false);
            initialRender.current = false;
        })
    }, []);

    //Renders Whenever pagination chnages
    useEffect(() => {
        if (!initialRender.current) {
            setInitialDataLoading(true);
            let filters = [];
            if (selectedUSerId?.value === "ALL") {
                filters = [
                    {
                        "colname": "session_start_date",
                        "isbetween": selectedDatePeriod?.value
                    }]
            } else {
                filters = [{
                    "colname": "id",
                    "isin": [
                        selectedUSerId?.value
                    ]
                },
                {
                    "colname": "session_start_date",
                    "isbetween": selectedDatePeriod?.value
                }
                ]
            };
            let dataToBeSend = {
                "offset": (activePage - 1) * parseInt(pageSize),
                "limit": parseInt(pageSize),
                "screen_type": "user_sessions",
                "excel_export": "false",
                "filters": filters,
                sort_type: sortOrder
            };
            AxiosInstance.post(userActivityUrl, dataToBeSend, {
                customHeaders: {
                    'Screen-Name': 'Settings',
                    "Screen-Section": "User Activity",
                    "Screen-Component": "Accordion Pagination",
                }
            }).then((response) => {
                let APIResponse = response?.data
                setRowData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
                setTotalRecords(APIResponse?.Total_rows || 0);
                setTotalPages(Math.ceil((APIResponse?.Total_rows
                    ? APIResponse.Total_rows
                    : 0) / pageSize));
            }).catch((error) => {
                setRowData([]);
            }).finally(() => {
                setInitialDataLoading(false);
            });
        }
    }, [pageSize, activePage])

    //Renders when ever sorting value chnages
    useEffect(() => {
        if (!initialRender.current) {
            setInitialDataLoading(true);
            let filters = [];
            if (selectedUSerId?.value === "ALL") {
                filters = [
                    {
                        "colname": "session_start_date",
                        "isbetween": selectedDatePeriod?.value
                    }]
            } else {
                filters = [{
                    "colname": "id",
                    "isin": [
                        selectedUSerId?.value
                    ]
                },
                {
                    "colname": "session_start_date",
                    "isbetween": selectedDatePeriod?.value
                }
                ]
            };
            let dataToBeSend = {
                "offset": (activePage - 1) * parseInt(pageSize),
                "limit": parseInt(pageSize),
                "screen_type": "user_sessions",
                "excel_export": "false",
                "filters": filters,
                sort_type: sortOrder
            };
            setServerSideExportPayload({ ...dataToBeSend, excel_export: "true" });
            AxiosInstance.post(userActivityUrl, dataToBeSend, {
                customHeaders: {
                    'Screen-Name': 'Settings',
                    "Screen-Section": "User Activity",
                    "Screen-Component": "Sorting Data",
                }
            }).then((response) => {
                let APIResponse = response?.data
                setRowData(APIResponse?.table_data?.length > 0 ? APIResponse?.table_data : []);
                setTotalRecords(APIResponse?.Total_rows || 0);
                setTotalPages(Math.ceil((APIResponse?.Total_rows
                    ? APIResponse.Total_rows
                    : 0) / pageSize));
            }).catch((error) => {
                setRowData([]);
            }).finally(() => {
                setInitialDataLoading(false);
            })
        }
    }, [sortOrder]);

    //Getting UserIds Url
    useEffect(() => {
        AxiosInstance.get(userIDsUrl, {
            customHeaders: {
                'Screen-Name': 'Settings',
                "Screen-Section": "User Activity",
                "Screen-Component": "Fetc User IDs",
            }
        }).then((response) => {
            setUserIDOptions(response.data?.data?.length > 0
                ? response.data.data : []
            )
        }).catch(() => {
            setUserIDOptions([]);
        })
    }, [])



    return (
        <div className="user-activity container-body mb-0">
            <div className="mx-3">
                <Suspense>
                    <Breadcrumbs params={breadcrumb} />
                </Suspense>
            </div>
            {/*Accordion Part*/}
            <div>
                <Container fluid className="tab-container settings side-tabs">
                    <Row xs={12}>
                        {/* Left Tab */}
                        <Col className="tabs-section1 leftTab" xs={2}>
                            <div className="d-grid gap-2 px-2">
                                {IsAuthorized("Settings", "emr_connectivity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/emr-connectivity");
                                        }}
                                    >
                                        EMR Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/clear-house-connectivity");
                                        }}
                                    >
                                        Clearing House Connectivity
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "other_settings") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/other-settings");
                                        }}
                                    >
                                        Other Settings
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "credentials") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/integration-credentials");
                                        }}
                                    >
                                        Integration Credentials
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "authorizations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/authorizations");
                                        }}
                                    >
                                        Authorizations
                                    </Button>
                                )}
                                {IsAuthorized("Settings", "configurations") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/configurations");
                                        }}
                                    >
                                        Configurations
                                    </Button>
                                )}

                                {localStorage.getItem("primaryDetailsSelectedTab") && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/primary-details");
                                        }}
                                    >
                                        Primary details
                                    </Button>
                                )}

                                {IsAuthorized("Settings", "user_activity") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons activeButton"
                                        onClick={() => {
                                            navigate("/settings/user-activity");
                                        }}
                                    >
                                        User Activity
                                    </Button>
                                )}
                                {IsAuthorized("DataPlayGround", "page_level") && (
                                    <Button
                                        variant="outline-primary"
                                        size="lg"
                                        className="buttons"
                                        onClick={() => {
                                            navigate("/settings/data-playground");
                                        }}
                                    >
                                        Data Playground
                                    </Button>
                                )}
                            </div>
                        </Col>

                        <Col className="tabs-section1 rightTab" xs={10}>

                            {/**************  Filters,Sort and Export **************/}
                            <div className='d-flex justify-content-end gap-3 align-items-baseline'>
                                <div ref={targetfilter} style={{ height: "50px" }}>
                                    <Button
                                        onClick={handleClick}
                                        variant="outline-primary"
                                        className="filter-icon"
                                    >
                                        <img
                                            src={filterIcon}
                                            alt="Filter Icon Not Found"
                                            className="FilterICon"
                                        />
                                    </Button>
                                    <Overlay
                                        show={showfilter}
                                        target={targetfilterstate}
                                        placement="bottom"
                                        container={targetfilter}
                                        containerPadding={20}
                                    >
                                        <Popover
                                            id="popover-basic"
                                            className="filter-popover pad-reduce"
                                        >
                                            <Form>
                                                <Popover.Body>
                                                    {/* <div className="d-flex justify-content-end">
                                                        <Button
                                                            className="px-2 py-1"
                                                        // onClick={clearFilters}
                                                        >
                                                            clear
                                                        </Button>
                                                    </div> */}

                                                    <SelectDropDown
                                                        label={"User ID"}
                                                        dropDownData={userIDOptions}
                                                        defaultVaue={selectedUSerId}
                                                        setDropDownDataState={setSelectedUSerId}
                                                        menuOptionsPosition={"absolute"}
                                                    />
                                                    <SelectDropDown
                                                        label={"Date Period"}
                                                        dropDownData={datePeriodOptions}
                                                        defaultVaue={selectedDatePeriod}
                                                        setDropDownDataState={setSelectedDatePeriod}
                                                        menuOptionsPosition={"absolute"}
                                                    />
                                                </Popover.Body>
                                                <Button
                                                    variant="primary"
                                                    className="d-block w-100 p-3 corners"
                                                    onClick={handleApplyFilters}
                                                >
                                                    Apply Filters
                                                    {filterLoader ? <LoaderComponent /> : ""}
                                                </Button>
                                            </Form>
                                        </Popover>
                                    </Overlay>
                                </div>

                                {sortOrder === "yes" ? (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                <strong>Oldest - Latest</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <div onClick={() => { setSortOrder("no") }} className="cursorPointer border border-primary rounded py-1 px-2">
                                            <img src={SortUp} alt="Sort" height={"15px"} />
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                <strong>Latest - Oldest</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <div onClick={() => { setSortOrder("yes") }} className="cursorPointer border border-primary rounded py-1 px-2">
                                            <img src={SortDown} alt="Sort" height={"15px"} />
                                        </div>
                                    </OverlayTrigger>
                                )}
                                {/* {IsAuthorized("BillingScreen", "export") && ( */}
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderExport}
                                    >
                                        <div
                                            className="d-inline-block"
                                            onClick={handleExportPopUp}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="icon border border-primary rounded p-1">
                                                <img
                                                    height={"22px"}
                                                    src={ExportIcon}
                                                    alt="Export"
                                                    className="pervisitExportImage"
                                                />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                {/* )} */}
                            </div>
                            {/**************  Filters Sort and Export **************/}
                            {initialDataLoading ? <DotsLoader height='h-100' /> :
                                <Accordion className="pcc-accordion accordion-denied-claims">
                                    {rowData.length > 0 ? (
                                        <>
                                            {rowData.map((row, index) => (
                                                <Accordion.Item
                                                    eventKey={index}
                                                    key={index}
                                                    className="mb-3 box-shadow position-relative"
                                                >
                                                    <div className="accordion-header py-3 position-relative">
                                                        {/* <Accordion.Header> */}
                                                        <div className="d-flex justify-content-between align-items-center w-100 ps-3 pe-5">
                                                            <Row className="w-100 denied-claims-row">
                                                                <Col >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        User
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.activity_user)
                                                                            ? row.activity_user
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        Session Started At
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.session_start_time_string)
                                                                            ? convertToSettingsTimeFormat(row.session_start_time_string)
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col  >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        Session Ended At
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.session_end_time_string)
                                                                            ? convertToSettingsTimeFormat(row.session_end_time_string)
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col  >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        Session Duration
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.duration)
                                                                            ? row.duration
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col  >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        IP Address
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.user_ip)
                                                                            ? row.user_ip
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col  >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        Agent ID
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.user_agent)
                                                                            ? row.user_agent
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                                <Col  >
                                                                    <div className="ft12 mb-1 accordion-denied-headers">
                                                                        Session ID
                                                                    </div>
                                                                    <p className="fs-12 accordion-denied-headers mb-2 fw-bold">
                                                                        {handleNullValues(row?.session_id)
                                                                            ? row.session_id
                                                                            : "--"}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <AccordionButton
                                                            className="accordion-arrow-btn"
                                                            onClick={() => {
                                                                accordionClick(viewActivity, row);
                                                            }}
                                                        ></AccordionButton>
                                                        {/* </Accordion.Header> */}
                                                    </div>
                                                    <Accordion.Body>
                                                        <Row className="flex-column">
                                                            <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
                                                                <div
                                                                    className="border boder-dark d-flex border-end rounded"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <p
                                                                        className={
                                                                            viewActivity === "yes"
                                                                                ? "mb-0 p-2 bg-primary rounded-start fs-14 text-white"
                                                                                : "mb-0 p-2 fs-14"
                                                                        }
                                                                        onClick={() => {
                                                                            handleViewAllChange("yes", row);
                                                                        }}
                                                                    >
                                                                        User Activity
                                                                    </p>
                                                                    <p
                                                                        className={
                                                                            viewActivity === "no"
                                                                                ? "mb-0 p-2 bg-primary rounded-end fs-14 text-white"
                                                                                : "mb-0 p-2 fs-14"
                                                                        }
                                                                        onClick={() => {
                                                                            handleViewAllChange("no", row);
                                                                        }}
                                                                    >
                                                                        Screens Accessed
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    {accordionBodySortOrder === "yes" ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    <strong>Oldest - Latest</strong>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <div onClick={() => {
                                                                                setAccordionBodySortOrder("no");
                                                                                handleAccordionBodySorting(viewActivity, row, "no");
                                                                            }}
                                                                                className="cursorPointer justify-content-end-end border border-primary rounded py-1 px-2">
                                                                                <img src={SortUp} alt="Sort" height={"13px"} />
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    ) : (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    <strong>Latest - Oldest</strong>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <div onClick={() => {
                                                                                handleAccordionBodySorting(viewActivity, row, "yes");
                                                                                setAccordionBodySortOrder("yes");
                                                                            }}
                                                                                className="cursorPointer border border-primary rounded py-1 px-2">
                                                                                <img src={SortDown} alt="Sort" height={"13px"} />
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {accordionBodyLoading ?
                                                                <DotsLoader background={"secondary"} /> :
                                                                <>
                                                                    <Row>
                                                                        {accordionBodyData?.map(
                                                                            (childernRows, index) => {
                                                                                return (
                                                                                    <Col md={4} className="mb-4">
                                                                                        <div
                                                                                            style={{
                                                                                                background: "white",
                                                                                            }}
                                                                                            className="py-3 px-3"
                                                                                        >

                                                                                            <Row className="mb-1 text-start">
                                                                                                <Col md={6}>
                                                                                                    <p className="mb-1 fs-12">
                                                                                                        Screen
                                                                                                    </p>
                                                                                                    <p className="fw-bold fs-14">
                                                                                                        {handleNullValues(
                                                                                                            childernRows?.screen
                                                                                                        )
                                                                                                            ?
                                                                                                            PascalCaseString(childernRows?.screen).replaceAll("_", " ")
                                                                                                            : "--"}
                                                                                                    </p>
                                                                                                </Col>
                                                                                                {viewActivity === "yes" ? <>
                                                                                                    <Col md={6}>
                                                                                                        <p className="mb-1 fs-12">
                                                                                                            Section
                                                                                                        </p>
                                                                                                        <p className="fw-bold fs-14">
                                                                                                            {handleNullValues(
                                                                                                                childernRows?.section
                                                                                                            )
                                                                                                                ?
                                                                                                                PascalCaseString(childernRows?.section).replaceAll("_", " ")
                                                                                                                : "--"}
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                    <Col md={6}>
                                                                                                        <p className="mb-1 fs-12">
                                                                                                            Element
                                                                                                        </p>
                                                                                                        <p className="fw-bold fs-14">
                                                                                                            {handleNullValues(
                                                                                                                childernRows?.component
                                                                                                            )
                                                                                                                ?
                                                                                                                PascalCaseString(childernRows?.component).replaceAll("_", " ")
                                                                                                                : "--"}
                                                                                                        </p>
                                                                                                    </Col>
                                                                                                    <Col md={6}>
                                                                                                        <p className="mb-1 fs-12">
                                                                                                            Action Performed At
                                                                                                        </p>
                                                                                                        <p className="fw-bold fs-12 mb-0">
                                                                                                            {handleNullValues(
                                                                                                                childernRows?.action_performed_at
                                                                                                            )
                                                                                                                ?
                                                                                                                convertToSettingsTimeFormat(childernRows?.action_performed_at)
                                                                                                                : "--"}
                                                                                                        </p>
                                                                                                    </Col></> :
                                                                                                    <Col md={6}>
                                                                                                        <p className="mb-1 fs-12">
                                                                                                            Duration
                                                                                                        </p>
                                                                                                        <p className="fw-bold fs-12 mb-0">
                                                                                                            {handleNullValues(
                                                                                                                childernRows?.duration
                                                                                                            )
                                                                                                                ?
                                                                                                                (childernRows?.duration)
                                                                                                                : "--"}
                                                                                                        </p>
                                                                                                    </Col>}
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Col>
                                                                                );
                                                                            }
                                                                        )}{" "}
                                                                    </Row>

                                                                    <Col md={12} className='text-center'>
                                                                        {viewMoreLoader ? <DotsLoader background="secondary" /> : ""}
                                                                        <button className='border border-primary p-2 rounded fs-14 bg-white'
                                                                            onClick={(e) => { handleViewMore(e, viewActivity, row) }}
                                                                            disabled={!(isViewMoreClickable)}
                                                                        >{"View More"}</button>
                                                                    </Col>
                                                                </>
                                                            }
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </>
                                    ) : (
                                        <div
                                            className="py-5"
                                            style={{
                                                textAlign: "center",
                                                // color: "red",
                                                margin: "5px",
                                            }}
                                        >
                                            No Data Found
                                        </div>
                                    )}

                                    {/* Pagination */}
                                    {rowData.length > 0 && (
                                        <div className="pagination-data">
                                            <Row>
                                                <Col md="4">
                                                    <div className="pagination-left">
                                                        <div>
                                                            {`Showing ${activePage * pageSize - pageSize + 1} - 
                                                            ${activePage * pageSize - pageSize + rowData.length}
                                                            out of ${totalRecords} cards`}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <Pagination className="pagination-center">
                                                        {activePage > 1 && (
                                                            <Pagination.First onClick={handlePageFirst} />
                                                        )}
                                                        {activePage > 1 && (
                                                            <Pagination.Prev onClick={handlePagePrevious} />
                                                        )}

                                                        {paginationItems}

                                                        {activePage < totalPages && (
                                                            <Pagination.Next onClick={handlePageNext} />
                                                        )}
                                                        {activePage < totalPages && (
                                                            <Pagination.Last onClick={handlePageLast} />
                                                        )}
                                                    </Pagination>
                                                </Col>
                                                <Col md="4">
                                                    <div className="pagination-right">
                                                        <div className="text">Show </div>
                                                        <div className="page-by">
                                                            <Dropdown onSelect={handlePageSize}>
                                                                <Dropdown.Toggle
                                                                    size="lg"
                                                                    variant="secondary"
                                                                    id="page-by"
                                                                >
                                                                    {pageSize}
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        eventKey="5"
                                                                        id="5"
                                                                        className={
                                                                            Number(pageSize) === 5 ? "active" : ""
                                                                        }
                                                                    >
                                                                        5
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        eventKey="10"
                                                                        id="10"
                                                                        className={
                                                                            Number(pageSize) === 10 ? "active" : ""
                                                                        }
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        eventKey="25"
                                                                        id="25"
                                                                        className={
                                                                            Number(pageSize) === 25 ? "active" : ""
                                                                        }
                                                                    >
                                                                        25
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        eventKey="50"
                                                                        id="50"
                                                                        className={
                                                                            Number(pageSize) === 50 ? "active" : ""
                                                                        }
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        eventKey="100"
                                                                        id="100"
                                                                        className={
                                                                            Number(pageSize) === 100 ? "active" : ""
                                                                        }
                                                                    >
                                                                        100
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="text"> cards per page</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Accordion>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* ModalPopup Form for Export Data Confirmation */}
            <Modal
                show={exportPopUpModal}
                onHide={CloseExportModal}
                animation={false}
                size="xl"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                scrollable
                contentClassName="rounded-4"
            >
                <Modal.Header
                    bsPrefix="modal-header app-modal-header px-4"
                    className="d-flex justify-content-center"
                >
                    <Modal.Title bsPrefix="h6 mb-0">
                        Select the columns that you want in the export file
                    </Modal.Title>
                </Modal.Header>
                <Form className="mb-0 mt-3">
                    <Modal.Body bsPrefix="modal-body px-4 py-3 serverExportModalHeight">
                        <Suspense fallback={<DotsLoader height="vh-25" />}>
                            <ServerSideExportMultiSection
                                rowData={exportColumns}
                                serverSideExportUrl={userActivityUrl}
                                serverSideExportPayload={serverSideExportPayload}
                                comments={"true"}
                                setexportPopUpModal={setexportPopUpModal}
                            />
                        </Suspense>
                    </Modal.Body>
                    <Modal.Footer className="px-0 pb-0"></Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}

export default UserActivity;