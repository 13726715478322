import React from 'react'

const RedirectURL = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
            <h1 className='content-center'>Redirected to un-authorized Page</h1>
        </div>
    )
}

export default RedirectURL
