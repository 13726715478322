import React from "react";
import { Spinner } from "react-bootstrap";

const LoaderComponent = () => {
  return (
    <Spinner
      animation="border"
      size="sm"
      className="mx-2"
      role="status"
    ></Spinner>
  );
};

export default React.memo(LoaderComponent);
