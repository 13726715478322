/*This import will be changed based upon the application URLS folder */
import axios from "axios";
import { UpdateUserSessionTokenUrl, refreshTokenUrl } from "../../Utils/Urls";
import {
  AxiosInstance,
  axiosRefreshInstance,
} from "../../Components/Util_Functions/AxiosInstance";

const RefreshToken = async (refreshToken) => {
  // let fetchResponse = await fetch(refreshTokenUrl, {
  //   method: "POST",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  //   credentials: "include",
  //   body: JSON.stringify({
  //     refresh: refreshToken,
  //   }),
  // })
  //   .then((response) => {
  //     return response.json();
  //   })
  //   .catch((error) => {
  //     return error.json();
  //   });
  // return fetchResponse;
  try {
    const response = await axiosRefreshInstance.post(refreshTokenUrl, {
      // Include necessary data such as refresh token in the request body
      refresh: refreshToken,
    });
    // console.log(response.data);
    const newToken = response.data;
    return newToken;
  } catch (error) {
    console.error("Failed to refresh token", error);
    throw error;
  }
};

const getNewAccessToken = async () => {
  let oldAccessToken = JSON.parse(localStorage.getItem("hnts_access_token"));
  let refreshToken = JSON.parse(localStorage.getItem("hnts_refresh_token"));
  let refreshTokenResponse = [];
  refreshTokenResponse = await RefreshToken(refreshToken);

  if (refreshTokenResponse?.access && refreshTokenResponse.refresh) {
    // Update the headers
    AxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${refreshTokenResponse.access}`;
    localStorage.setItem(
      "hnts_access_token",
      JSON.stringify(
        refreshTokenResponse?.access ? refreshTokenResponse.access : "No Value"
      )
    );
    localStorage.setItem(
      "hnts_refresh_token",
      JSON.stringify(
        refreshTokenResponse?.refresh
          ? refreshTokenResponse.refresh
          : "No Value"
      )
    );

    let payload = {
      old_access: oldAccessToken,
      new_access: refreshTokenResponse?.access,
    };

    let headers = {
      headers: {
        Authorization: `Bearer ${refreshTokenResponse.access}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
      .post(
        UpdateUserSessionTokenUrl,
        payload,
        { withCredentials: true },
        headers
      )
      .then((response) => {
        console.log(response);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  let newAccessToken = refreshTokenResponse?.access;
  console.log("access token first");
  return newAccessToken;
};

export default getNewAccessToken;
