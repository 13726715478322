import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/css/Authentication.css";
import { LogoutURL } from "../../../Utils/Urls";
import { AxiosInstance } from "../../../Components/Util_Functions/AxiosInstance";

function Logout() {
  const location = useLocation();

  // let sessionId = JSON.parse(localStorage.getItem("sessionId"));
  let key = JSON.parse(localStorage.getItem("hnts_access_token"));

  const defaultKey =
    location.state && location.state.list && location.state.list;

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    let payload = {
      access_token: key,
      // session_id: sessionId,
    };

    AxiosInstance.post(LogoutURL, payload)
      .then((response) => {
        console.log("logout success");
      })
      .catch(async (error) => {
        console.log(error?.response);
      })
      .finally(() => {
        if (defaultKey === "manuallogout") {
          localStorage.clear();
          navigate("/login", { state: "manuallogout" });
          sessionStorage.setItem("sessionlogout", "manuallogout");
        } else {
          localStorage.clear();
          sessionStorage.setItem("sessionlogout", "autologout");
          navigate("/login", { state: "autologout" });
        }
      });

    if (defaultKey === "manuallogout") {
      localStorage.clear();
      navigate("/login", { state: "manuallogout" });
      sessionStorage.setItem("sessionlogout", "manuallogout");
    } else {
      localStorage.clear();
      sessionStorage.setItem("sessionlogout", "autologout");
      navigate("/login", { state: "autologout" });
    }
  }, []);
  return <div>Logging out..</div>;
}

export default Logout;
