export function getTheme(themeName) {
  if (themeName === "theme1") {
    let reqThemeStyles = {
      "--primary-color": "6, 110, 210",
      "--secondary-color": "248, 248, 248",
      "--font-color": "black",
      "--font-family": "Outfit, serif",
      "--button-color1": "13,110,253",
    };
    return reqThemeStyles;
  } else if (themeName === "theme2") {
    let reqThemeStyles = {
      "--primary-color": "124, 185, 232",
      "--secondary-color": "255, 192, 203",
      "--font-color": "black",
      "--font-family": "Marker Felt, fantasy",
      "--button-color1": "13,110,253",
    };
    return reqThemeStyles;
  }
}
