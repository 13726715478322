import AllTimeFormats from "../../Components/Util_Functions/AllTimeFormats";
import DateFormatter from "../DateFormatter";

export const convertToSettingsTimeFormat = (dateToBeConverted) => {
  const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));
  let timeZone =
    hnts_user && hnts_user.settings && hnts_user.settings.time_zone;
  let requiredTimeFormat = "None";
  if (
    timeZone === "US-Central" ||
    timeZone === "None" ||
    timeZone === undefined
  ) {
    requiredTimeFormat = "(CST)";
  } else if (timeZone === "US-Eastern") {
    requiredTimeFormat = "(EST)";
  } else if (timeZone === "US-Mountain") {
    requiredTimeFormat = "(MST)";
  } else if (timeZone === "US-Pacific") {
    requiredTimeFormat = "(PST)";
  }
  const date = new Date(dateToBeConverted);
  let requiredDateTime = AllTimeFormats(date);
  return `${DateFormatter(requiredDateTime[0])} ${requiredDateTime[1]}  ${requiredTimeFormat}`;
};
