import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { EmailRedirect } from "../../../Utils/Urls";
import { Row, Spinner, Toast } from "react-bootstrap";

function ForgotPasswordForm() {

  const AxiosInstance = axios.create();

  let navigate = useNavigate();

  const initialValues = {
    email: ""
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  console.log(formValues);
  // handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }
  // handle form submit
  const handleLogin = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSpinner(true);
      var dataTobeSend = { email: formValues.email };
      AxiosInstance.post(EmailRedirect, dataTobeSend).then((response) => {
        console.log(response.data.detail);
        setFormValues({ email: "" });
        setIsSubmit(false);
        setShowToast(true);
        setToastMsg(response.data.detail);
        setToastType("success");
        setSpinner(false);
      }).catch((error) => {
        if (error.response.status === 400) {
          setShowToast(true);
          setToastMsg("This email is not registered. Please try with your registered email address.");
          setToastType("error");
        }
        setSpinner(false);
      })
    }
  }, [formErrors])


  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    return errors;
  };



  return (
    <div className="form-section">
      {showToast ? (
        <Row className="toast-row toast-abs">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            className={toastType}
            delay={3000}
            autohide
          >
            <Toast.Body className="text-center">{toastMsg}</Toast.Body>
          </Toast>
        </Row>
      ) : (
        ""
      )}
      <div className="form-header">Forgot Password</div>
      <div className="form-msg">
        {formErrors.email && (
          <div className="login-text-error">{formErrors.email}</div>
        )}

      </div>

      <form onSubmit={handleLogin}>
        <div className="form-field">
          <label className="valid">
            Email ID
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="John@gmail.com"
            value={formValues.email}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-4" />



        <div className="form-footer-msg"></div>
        <Link className='text-center d-block mb-2 mt-1' to="/login">Back to login</Link>
        <button className="submit-btn">
          <div className="submit-text">Submit
            {spinner && (
              <span
                style={{
                  paddingLeft: "10px",
                  boxSizing: "border-box",
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                ></Spinner>
              </span>
            )}</div>
        </button>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;