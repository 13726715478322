import React from 'react'
import ColumnToggleIcon from "../../assets/images/404.svg";
import "./style.css";
const PageNotFound = () => {
  return (
    <div className='page-404 bg-f8 d-flex justify-content-center align-items-center w-100 vh-100'>
      <div className='w-100'>
        <div className='bg-f8 bg-padding px-0'>
          <div className='position-relative'>
            <img
              src={ColumnToggleIcon}
              alt="Export"
              className='w-100'/>
            <div className="position-absolute pageNotFound">
              <h1 className='status-num mb-3 mt-4 pt-1'>4O4</h1>
              <p className='status-tittle mb-0'>Oops! Page Not Found</p>
              <p>The page you are looking for couldn't be found</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PageNotFound